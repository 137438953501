import View from './View';
import { getUser } from '@/utils/local';

const aiTutorStatsRoutes = [
  getUser()?.scopes.includes('ai-tutor-stats:view')
    ? {
        name: 'AI Tutor Stats',
        path: '/ai-tutor/stats',
        component: View
      }
    : null
];

export const aiTutorStatsRouter = aiTutorStatsRoutes.filter(function (x) {
  return x !== null;
});
