var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-tabs',{staticClass:"mb-4",attrs:{"color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.types),function(type,index){return _c('v-tab',{key:index},[_vm._v(" "+_vm._s(type)+" ")])}),1),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('data-table',{key:_vm.dataTableKey,attrs:{"allow-add":false,"headers":_vm.headers,"loader":_vm.loadData,"title":"In App Products"},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"primary-action",fn:function(){return [(_vm.tab !== 0 && _vm.tab !== 1)?_c('v-btn',{attrs:{"disabled":!_vm.getUser().scopes.includes('ai-tutor-iaps:edit'),"color":"primary","elevation":"0"},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-disk")]),_vm._v(" Save ")],1):_vm._e()]},proxy:true},{key:"sku",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.sku)+" ")]}},{key:"title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.listings['en-US'].title)+" ")]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.listings['en-US'].description)+" ")]}},(_vm.tab === 0)?{key:"discount_mode_android",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mb-4",attrs:{"hide-details":""},model:{value:(item.discount_mode_android),callback:function ($$v) {_vm.$set(item, "discount_mode_android", $$v)},expression:"item.discount_mode_android"}})]}}:null,(_vm.tab === 1)?{key:"discount_mode",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mb-4",attrs:{"hide-details":""},model:{value:(item.discount_mode),callback:function ($$v) {_vm.$set(item, "discount_mode", $$v)},expression:"item.discount_mode"}})]}}:null,(_vm.tab === 2)?{key:"mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+"%")]}}:(_vm.tab === 3)?{key:"mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.value))]}}:null,{key:"hide",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","label":"Active"},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"action",fn:function(ref){
var item = ref.item;
return [(_vm.getUser().scopes.includes('ai-tutor-iaps-offers:view'))?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil ")]):_vm._e()]}}],null,true)}),_c('loading-dialog',{attrs:{"message":"Updating Products"},model:{value:(_vm.editLoading),callback:function ($$v) {_vm.editLoading=$$v},expression:"editLoading"}}),_c('loading-dialog',{attrs:{"message":"Please wait..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }