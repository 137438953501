<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab
        v-if="
          getUser()
            ? getUser().scopes.includes('ai-tutor-web-aps-prices:view')
            : false
        "
        >Packages</v-tab
      >
      <v-tab
        v-if="
          getUser()
            ? getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
            : false
        "
        >Coupons</v-tab
      >
      <v-tab
        v-if="
          getUser()
            ? getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
            : false
        "
        >Spin the Wheel</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="
          getUser()
            ? getUser().scopes.includes('ai-tutor-web-aps-prices:view')
            : false
        "
      >
        <package-view />
      </v-tab-item>
      <v-tab-item
        v-if="
          getUser()
            ? getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
            : false
        "
      >
        <coupons-view />
      </v-tab-item>
      <v-tab-item
        v-if="
          getUser()
            ? getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
            : false
        "
      >
        <data-table
          :loader="loadData"
          :headers="headers"
          :allow-add="false"
          title="Spin the Wheel"
        >
          <template #primary-action>
            <v-btn color="primary" elevation="0" @click="save(2)">
              <v-icon>mdi-disk</v-icon>
              Save
            </v-btn>
          </template>

          <template #web="{ item }"> {{ item.value }}% </template>

          <template #hide="{ item }">
            <v-checkbox
              label="Active"
              color="primary"
              v-model="item.isActive"
            ></v-checkbox>
          </template>
        </data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import CouponsView from '@/views/offers_ai-tutor/CouponsView.vue';
import PackageView from '@/views/offers_ai-tutor/PackageView.vue';

export default {
  name: 'AiTutorWebAppPurchaseOffersView',
  components: { PackageView, CouponsView, DataTable },
  data: () => ({
    tab: 0,
    headers: [
      {
        text: 'Percentage',
        value: 'web',
        sortable: false
      },
      {
        text: 'Action',
        value: 'hide',
        sortable: false
      }
    ],
    items: [],
    spinTheWheelData: {}
  }),
  methods: {
    getUser,

    async save(type) {
      let data = this.items
        .filter((item) => item.isActive)
        .map((item) => ({
          type,
          value: item.value
        }));

      const existingData = this.spinTheWheelData.web.filter(
        (item) => item.type !== type
      );

      this.spinTheWheelData.web = [...data, ...existingData];

      if (
        this.spinTheWheelData.web.length < 3 ||
        this.spinTheWheelData.web.length > 7
      ) {
        return this.$toast.error(
          'Please select a minimum of 3 and a maximum of 7 items from spin the wheel'
        );
      }

      const rearrangedArray = [];
      while (this.spinTheWheelData.web.length > 0) {
        rearrangedArray.push(this.spinTheWheelData.web.shift());
        rearrangedArray.push(
          this.spinTheWheelData.web.splice(
            this.spinTheWheelData.web.findIndex(
              (item) =>
                item.type !== rearrangedArray[rearrangedArray.length - 1].type
            ),
            1
          )[0]
        );
      }

      this.spinTheWheelData.web = rearrangedArray.filter(
        (item) => item !== undefined
      );

      await fetch(this.$aiTutorApiUrl + '/spin-of-the-wheel/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.spinTheWheelData)
      });

      this.$toast.success('Spin the wheel percentages saved successfully');
    },

    async loadSpinTheWheelData(type) {
      const aiTutorSpinTheWheelData = await fetch(
        this.$aiTutorApiUrl + '/spin-of-the-wheel/?platform=0'
      ).then((res) => res.json());

      this.spinTheWheelData = aiTutorSpinTheWheelData[0] || {
        web: []
      };

      let data = this.spinTheWheelData.web;
      data = data.filter((item) => item.type === type);

      const startValue = 10;
      const endValue = 70;

      const finalItems = [];
      for (let i = startValue; i <= endValue; i += startValue) {
        const index = data.findIndex((item) => item.value === i);

        const item = {
          value: i,
          isActive: index >= 0
        };

        finalItems.push(item);
      }

      return finalItems;
    },

    async loadData() {
      const data = await this.loadSpinTheWheelData(2);
      this.items = data;
      return data;
    }
  }
};
</script>

<style scoped></style>
