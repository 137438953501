<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">
      {{ this.isEdit ? 'Update Offer' : 'Create New Offer' }}
    </p>

    <v-select
      v-model="offer.type"
      outlined
      dense
      label="Offer Type"
      class="span-2"
      :rules="[requiredV2]"
      :items="types"
      disabled
    >
    </v-select>

    <div
      style="
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-column-gap: 20px;
      "
      class="span-2"
    >
      <v-select
        v-model="offer.offer_name"
        outlined
        dense
        label="Offer Reference Name"
        :rules="[required('A name must be provided')]"
        :items="offers"
        item-text="text"
        item-value="value"
        @change="
          () =>
            (offer.discount_percentage = offers.find(
              (o) => o.value === offer.offer_name
            ).discount_percentage)
        "
      >
      </v-select>

      <v-text-field
        v-if="offer.type === 0 && isPlatformIso"
        v-model="offer.discount_percentage"
        dense
        :rules="[requiredPercentage()]"
        label="Discount %"
        outlined
        disabled
      />
      <v-text-field
        v-if="offer.type === 1 && !isPlatformIso"
        v-model="offer.discount_percentage"
        dense
        :rules="[required('Trial (in days) is required!')]"
        label="Trial (days)"
        outlined
        disabled
      />
    </div>
    <v-file-input
      :hint="
        isEdit && offer
          ? 'Last file ' +
            offer.filename +
            ' uploaded on ' +
            formatDate(offer.updated_at) +
            ' with ' +
            offer.length +
            ' codes remaining.'
          : ''
      "
      persistent-hint
      accept=".txt,.csv"
      class="span-2"
      outlined
      dense
      prepend-icon=""
      label="Code File"
      @change="filePicked"
      :clearable="false"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
    <v-text-field
      v-model="offer.expiry"
      :rules="[required('Expiry Date must be provided')]"
      class="span-2"
      type="datetime-local"
      outlined
      dense
      label="Expiry Date"
    ></v-text-field>
    <v-checkbox v-model="offer.active" dense label="Active" hide-details />

    <loading-dialog v-model="loading" message="Fetching Offer Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredPercentage, requiredV2 } from '@/utils/validators';
import { IapsOffersService } from '@/services/iaps-offers-service';
import dayjs from 'dayjs';

export default {
  name: 'AiTutorInAppPurchaseOffersForm',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new IapsOffersService(),
    offer: {
      active: true,
      type: 1,
      platform: 1
    },
    types: [
      {
        text: 'Discount',
        value: 0
      },
      {
        text: 'Trial',
        value: 1
      }
    ],
    offers: []
  }),

  computed: {
    isPlatformIso() {
      if (
        !(
          this.$route.query &&
          this.$route.query.p &&
          this.$route.query.p === '1'
        )
      ) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    if (this.isPlatformIso) {
      this.offers = [
        { text: '10p_off', value: '10p_off', discount_percentage: 10 },
        { text: '20p_off', value: '20p_off', discount_percentage: 20 },
        { text: '30p_off', value: '30p_off', discount_percentage: 30 },
        { text: '40p_off', value: '40p_off', discount_percentage: 40 },
        { text: '50p_off', value: '50p_off', discount_percentage: 50 },
        { text: '60p_off', value: '60p_off', discount_percentage: 60 },
        { text: '70p_off', value: '70p_off', discount_percentage: 70 }
      ];
    } else {
      this.offers = [
        { text: '3d-Trial', value: '3d_off', discount_percentage: 3 },
        { text: '5d-Trial', value: '5d_off', discount_percentage: 5 },
        { text: '7d-Trial', value: '7d_off', discount_percentage: 7 },
        { text: '9d-Trial', value: '9d_off', discount_percentage: 9 },
        { text: '11d-Trial', value: '11d_off', discount_percentage: 11 },
        { text: '13d-Trial', value: '13d_off', discount_percentage: 13 },
        { text: '15d-Trial', value: '15d_off', discount_percentage: 15 }
      ];
    }

    if (this.$route.query.p === '1') {
      this.offer.type = 1;
      this.offer.platform = 1;
    } else {
      this.offer.type = 0;
      this.offer.platform = 0;
    }

    this.loadOffer();
  },

  methods: {
    required,
    requiredV2,
    requiredPercentage,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async loadOffer() {
      if (!this.$route.query.id) {
        this.offer.product_id = this.$route.query.product;
        return;
      }
      this.isEdit = true;
      this.loading = true;

      let offers = await fetch(
        this.$aiTutorApiUrl +
          `/subscription-offers/?platform=${this.$route.query.p}`
      ).then((res) => res.json());

      this.offer = offers.find((o) => o.id === this.$route.query.id);
      this.offer.expiry = new Date(this.offer.expiry)
        .toISOString()
        .slice(0, -1);

      this.loading = false;
    },
    filePicked(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let codes = [];
        const main = e.target.result.replaceAll('\n', ',');
        const mainArr = main.split(',');

        if (this.isPlatformIso) {
          // for skipping 2nd column in ISO CSV file
          for (let i = 0; i < mainArr.length; i += 2) {
            if (
              mainArr[i] &&
              mainArr[i] !== '' &&
              !mainArr[i].startsWith('Promo')
            )
              codes.push(mainArr[i]);
          }
        } else {
          for (let i = 0; i < mainArr.length; i += 1) {
            if (
              mainArr[i] &&
              mainArr[i] !== '' &&
              !mainArr[i].startsWith('Promo')
            )
              codes.push(mainArr[i]);
          }
        }

        this.offer.codes = codes;
        this.offer.length = this.offer.codes.length;
        this.offer.filename = file.name;
        this.offer.updated_at = new Date();
      };
      reader.readAsText(file);
    },
    preCheck(context) {
      if (!context.validate()) return false;

      if (!this.isEdit) {
        if (!this.offer.codes || this.offer.codes.length <= 0) {
          context.reportError({
            title: 'Invalid Offer Data',
            description: 'Selected file is empty or has wrong format'
          });

          return false;
        }
      }

      return true;
    },

    async submit(context) {
      if (this.preCheck(context)) {
        this.offer.discount_percentage = +this.offer.discount_percentage;

        if (!this.isPlatformIso) this.offer.platform = 1;

        try {
          context.changeLoadingMessage(
            this.isEdit ? 'Updating Offer' : 'Creating A New Offer'
          );
          await fetch(this.$aiTutorApiUrl + '/subscription-offers/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.offer)
          });
          return true;
        } catch (e) {
          context.reportError({
            title: `Error occurred while ${
              this.isEdit ? 'updating' : 'creating'
            } Offer`,
            description: e.toString()
          });
          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
