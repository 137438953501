<template>
  <div>
    <data-table
      ref="table"
      title="Coupons"
      :allow-add="
        getUser()
          ? getUser().scopes.includes('ai-tutor-web-aps-coupons:new')
          : false
      "
      :loader="loadCoupons"
      :headers="headers"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('ai-tutor-web-aps-coupons:delete')
            ? deleteCoupon
            : null
          : null
      "
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('ai-tutor-web-aps-coupons:edit')
            ? editCoupon
            : null
          : null
      "
      @add-new="showNewDialog"
      :show-search="false"
    >
      <template #percent_off="{ item }">
        {{ item.percent_off && item.percent_off + '%' }}
      </template>

      <template #primary-action>
        <v-checkbox
          color="primary"
          v-model="appStatusData.enablePromoCode"
          label="Show Promo Codes Popup"
          @change="updateAppStatus"
          class="mt-1 mr-4"
        ></v-checkbox>
      </template>
    </data-table>
    <v-dialog v-model="showDialog" width="400">
      <v-card style="padding: 40px">
        <v-form ref="coupon">
          <v-text-field
            v-model="name"
            outlined
            dense
            label="Coupon Name"
            :rules="[required()]"
          />
          <v-text-field
            v-model="percent_off"
            outlined
            v-if="!isEdit"
            dense
            label="Percentage Off (%)"
            :rules="[requiredPercentage()]"
            type="number"
          />
          <v-btn
            style="width: 100%"
            elevation="0"
            color="primary"
            @click="saveCoupon"
          >
            {{ isEdit ? 'Update' : 'Create' }} Coupon
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" :message="loadingMessage" />
    <error-dialog v-model="error" :error="errorValue" />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { getUser } from '@/utils/local';
import { required, requiredPercentage } from '@/utils/validators';
import { WebAppOfferService } from '@/services/web-app-offers-service';

export default {
  name: 'AiTutorCouponsView',
  components: { ErrorDialog, LoadingDialog, DataTable },

  data: () => ({
    id: '',
    name: '',
    percent_off: 0,
    showDialog: false,
    service: new WebAppOfferService(),
    appStatusData: {},
    headers: [
      {
        value: 'name',
        text: 'Name'
      },
      {
        value: 'percent_off',
        text: 'Percentage Off'
      }
    ],
    isEdit: false,
    error: false,
    errorValue: {},
    loading: false,
    loadingMessage: ''
  }),

  mounted() {
    this.getAppStatusData();
  },

  methods: {
    getUser,
    required,
    requiredPercentage,

    showNewDialog() {
      this.isEdit = false;
      this.showDialog = true;
    },

    async loadCoupons() {
      return (await this.service.fetchAllCoupon()).data;
    },

    async getAppStatusData() {
      this.appStatusData = (await this.$axios.get('/app-status')).data;
    },

    async updateAppStatus() {
      this.appStatusLoading = true;
      try {
        await this.$axios.patch('/app-status', this.appStatusData);
      } catch (e) {
        console.log(e);
        this.$toast.error('Failed to update promo codes popup status');
      } finally {
        this.appStatusLoading = false;
        this.$toast.success('Promo codes popup status updated successfully');
      }
    },

    async saveCoupon() {
      if (this.$refs.coupon.validate()) {
        try {
          if (this.name.includes('spin' || 'Spin')) {
            this.error = true;
            this.errorValue = {
              title: `Error while ${
                this.isEdit ? 'Updating' : 'Creating'
              } Coupon`,
              description: 'Coupon name cannot contain spin'
            };
            return;
          }

          this.loading = true;
          if (!this.isEdit) {
            this.loadingMessage = 'Creating New Coupon';
          } else {
            this.loadingMessage = 'Updating Coupon';
          }

          const coupon = {
            name: this.name
          };
          if (this.isEdit) {
            await this.service.updateCoupon(this.id, coupon);
          } else {
            coupon['percentage_off'] = Number(this.percent_off);
            await this.service.createCoupon(coupon);
          }

          this.$refs.coupon.reset();

          this.refresh();

          this.showDialog = false;
          this.loading = false;
          this.isEdit = false;
        } catch (e) {
          this.loading = false;
          this.error = true;
          this.errorValue = {
            title: `Error while ${
              this.isEdit ? 'Updating' : 'Creating'
            } Coupon`,
            description: e?.data?.error ?? 'Some error occurred'
          };
        }
      }
    },

    refresh() {
      let btns = document.getElementsByClassName('refresh');
      for (let i = 0; i < btns.length; i++) {
        btns[i].click();
      }
    },

    async deleteCoupon(item) {
      return this.service.deleteCoupon(item.id);
    },

    editCoupon(item) {
      this.id = item.id;
      this.name = item.name;
      this.percent_off = item.percent_off;
      this.isEdit = true;
      this.showDialog = true;
    }
  }
};
</script>

<style scoped></style>
