<template>
  <div>
    <data-table
      ref="table"
      title="Packages"
      :loader="loadPackages"
      :headers="headers"
      :allow-add="false"
      :edit-handler="null"
      :show-search="false"
      :view-handler="viewHandler"
      :key="dataTableKey"
    >
    </data-table>

    <loading-dialog v-model="loading" :message="loadingMessage" />
    <error-dialog v-model="error" :error="errorValue" />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { getUser } from '@/utils/local';
import { required, requiredPercentage } from '@/utils/validators';
import { WebAppOfferService } from '@/services/web-app-offers-service';
const { format, parseISO } = require('date-fns');

export default {
  name: 'AiTutorPackageView',
  components: {
    ErrorDialog,
    LoadingDialog,
    DataTable
  },

  data: () => ({
    webCoupens: [],
    webAppBanner: {},
    dataTableKey: 0,

    packages: [],

    id: '',
    selectedCoupon: '',
    showDialog: false,
    service: new WebAppOfferService(),
    coupons: [],
    headers: [
      {
        value: 'name',
        text: 'Name'
      },
      {
        value: 'description',
        text: 'Description'
      }
    ],
    isEdit: false,
    error: false,
    errorValue: {},
    loading: false,
    loadingMessage: ''
  }),

  methods: {
    getUser,
    required,
    requiredPercentage,

    formateDate(inputDate, outputFormat) {
      const dateObj1 = parseISO(inputDate);
      const formattedDate = format(dateObj1, outputFormat);
      return formattedDate;
    },

    setBannersDetails(response) {
      const webBannerIndex = response.data.sales.findIndex(
        (sale) => sale.platform === 1
      );

      if (webBannerIndex !== -1) {
        const webBanner = response.data.sales[webBannerIndex];
        this.webAppBanner.id = webBanner.id;
        this.webAppBanner.name = webBanner.name;
        this.webAppBanner.description = webBanner.description;
        this.webAppBanner.percentage_off = webBanner.percentage_off;
        this.webAppBanner.is_active = webBanner.is_active;
        this.webAppBanner.createdAt = webBanner.createdAt;
        this.webAppBanner.sale_period = this.formateDate(
          webBanner.sale_period,
          'yyyy-MM-dd hh:mm:ss a'
        );

        this.webAppBanner.currentDate = new Date().toISOString();
        this.webAppBanner.currentDate = this.formateDate(
          this.webAppBanner.currentDate,
          'yyyy-MM-dd hh:mm:ss a'
        );
      }
    },

    async loadPackages() {
      await this.$axios
        .get('/sales')
        .then((response) => {
          this.setBannersDetails(response);
        })
        .catch((error) => {
          console.error(error);
        });

      this.webCoupens = await this.service.fetchAllCoupon();

      this.webCoupens = this.webCoupens.data.map((item) => {
        return {
          label: item.name + ` (${item.percent_off}%)`,
          value: item.id,
          ...item
        };
      });

      this.webCoupens.map((item) => {
        if (item.id === this.webAppBanner.percentage_off) {
          this.selectedCoupon = item.value;
        }
      });

      let packages = await this.service.fetchAllPackages();
      this.packages = packages.data;
      this.packages = this.packages.filter((item) => item.name === 'ai_tutor');

      return this.packages;
    },

    refresh() {
      let btns = document.getElementsByClassName('refresh');
      for (let i = 0; i < btns.length; i++) {
        btns[i].click();
      }
    },

    viewHandler(item) {
      this.$router.push({ name: 'PricesView', params: { id: item.id } });
    }
  }
};
</script>
