var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleForm',{attrs:{"onSubmit":_vm.submit},on:{"done":function($event){return _vm.$router.back()}}},[_c('p',{staticClass:"span-2 form__title"},[_vm._v(" "+_vm._s(this.isEdit ? 'Update Offer' : 'Create New Offer')+" ")]),_c('v-select',{staticClass:"span-2",attrs:{"outlined":"","dense":"","label":"Offer Type","rules":[_vm.requiredV2],"items":_vm.types,"disabled":""},model:{value:(_vm.offer.type),callback:function ($$v) {_vm.$set(_vm.offer, "type", $$v)},expression:"offer.type"}}),_c('div',{staticClass:"span-2",staticStyle:{"display":"grid","grid-template-columns":"2fr 1fr","grid-column-gap":"20px"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Offer Reference Name","rules":[_vm.required('A name must be provided')],"items":_vm.offers,"item-text":"text","item-value":"value"},on:{"change":function () { return (_vm.offer.discount_percentage = _vm.offers.find(
            function (o) { return o.value === _vm.offer.offer_name; }
          ).discount_percentage); }},model:{value:(_vm.offer.offer_name),callback:function ($$v) {_vm.$set(_vm.offer, "offer_name", $$v)},expression:"offer.offer_name"}}),(_vm.offer.type === 0 && _vm.isPlatformIso)?_c('v-text-field',{attrs:{"dense":"","rules":[_vm.requiredPercentage()],"label":"Discount %","outlined":"","disabled":""},model:{value:(_vm.offer.discount_percentage),callback:function ($$v) {_vm.$set(_vm.offer, "discount_percentage", $$v)},expression:"offer.discount_percentage"}}):_vm._e(),(_vm.offer.type === 1 && !_vm.isPlatformIso)?_c('v-text-field',{attrs:{"dense":"","rules":[_vm.required('Trial (in days) is required!')],"label":"Trial (days)","outlined":"","disabled":""},model:{value:(_vm.offer.discount_percentage),callback:function ($$v) {_vm.$set(_vm.offer, "discount_percentage", $$v)},expression:"offer.discount_percentage"}}):_vm._e()],1),_c('v-file-input',{staticClass:"span-2",attrs:{"hint":_vm.isEdit && _vm.offer
        ? 'Last file ' +
          _vm.offer.filename +
          ' uploaded on ' +
          _vm.formatDate(_vm.offer.updated_at) +
          ' with ' +
          _vm.offer.length +
          ' codes remaining.'
        : '',"persistent-hint":"","accept":".txt,.csv","outlined":"","dense":"","prepend-icon":"","label":"Code File","clearable":false},on:{"change":_vm.filePicked},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}])}),_c('v-text-field',{staticClass:"span-2",attrs:{"rules":[_vm.required('Expiry Date must be provided')],"type":"datetime-local","outlined":"","dense":"","label":"Expiry Date"},model:{value:(_vm.offer.expiry),callback:function ($$v) {_vm.$set(_vm.offer, "expiry", $$v)},expression:"offer.expiry"}}),_c('v-checkbox',{attrs:{"dense":"","label":"Active","hide-details":""},model:{value:(_vm.offer.active),callback:function ($$v) {_vm.$set(_vm.offer, "active", $$v)},expression:"offer.active"}}),_c('loading-dialog',{attrs:{"message":"Fetching Offer Data"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }