<template>
  <v-card class="pa-4">
    <v-row align="center" justify="space-between">
      <v-col cols="auto">
        <h2 class="text-h5">AI Tutor Stats</h2>
      </v-col>

      <v-col cols="auto">
        <v-chip class="chip" color="primary" v-if="data.accuracy">
          Accuracy: {{ (data.accuracy * 100).toFixed(2) }}%
        </v-chip>
      </v-col>
    </v-row>

    <div v-if="chartData && chartData.datasets[0].data[2] > 0">
      <pie-chart :chart-data="chartData" :options="options" />
    </div>

    <p
      v-else-if="chartData && chartData.datasets[0].data[2] === 0"
      class="text-center"
    >
      No data available!
    </p>

    <loading-dialog v-model="loadingDialog" message="Please Wait..." />
  </v-card>
</template>

<script>
import LoadingDialog from '../../components/LoadingDialog';
import pieChart from '../../utils/charts/pie-chart';

export default {
  components: { LoadingDialog, pieChart },

  data: () => ({
    loading: false,
    loadingDialog: false,

    data: {},

    chartData: null,

    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  }),

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loadingDialog = true;

      try {
        this.data = await fetch(
          this.$aiTutorApiUrl + '/messages/admin/right-wrong'
        ).then((res) => res.json());

        this.chartData = {
          labels: ['Correct', 'Wrong', 'Total'],
          datasets: [
            {
              label: 'Performance',
              backgroundColor: ['#66BB6A', '#FF5252', '#2196F3'],
              data: [this.data.correct, this.data.wrong, this.data.total]
            }
          ]
        };
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loadingDialog = false;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
