<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-prices:view') : false
        "
        >Packages</v-tab
      >
      <v-tab
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
        >Coupons</v-tab
      >
      <v-tab
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
        >Spin the Wheel</v-tab
      >
      <v-tab
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
        >Spin the Wheel (Coins)</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-prices:view') : false
        "
      >
        <package-view />
      </v-tab-item>
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
      >
        <coupons-view />
      </v-tab-item>
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
      >
        <data-table
          :loader="loadData"
          :headers="headers"
          :allow-add="false"
          title="Spin the Wheel"
        >
          <template #primary-action>
            <v-btn color="primary" elevation="0" @click="save(2)">
              <v-icon>mdi-disk</v-icon>
              Save
            </v-btn>
          </template>

          <template #web="{ item }"> {{ item.value }}% </template>

          <template #hide="{ item }">
            <v-checkbox
              label="Active"
              color="primary"
              v-model="item.isActive"
            ></v-checkbox>
          </template>
        </data-table>
      </v-tab-item>
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('web-aps-coupons:view') : false
        "
      >
        <data-table
          :loader="loadDataCoins"
          :headers="headers"
          :allow-add="false"
          title="Spin the Wheel (Coins)"
        >
          <template #primary-action>
            <v-btn color="primary" elevation="0" @click="save(3)">
              <v-icon>mdi-disk</v-icon>
              Save
            </v-btn>
          </template>

          <template #web="{ item }"> {{ item.value }} </template>

          <template #hide="{ item }">
            <v-checkbox
              label="Active"
              color="primary"
              v-model="item.isActive"
            ></v-checkbox>
          </template>
        </data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import CouponsView from '@/views/offers/CouponsView.vue';
import PackageView from '@/views/offers/PackageView.vue';

export default {
  name: 'WebAppPurchaseOffersView',
  components: { PackageView, CouponsView, DataTable },
  data: () => ({
    tab: 0,
    headers: [
      {
        text: 'Percentage',
        value: 'web',
        sortable: false
      },
      {
        text: 'Action',
        value: 'hide',
        sortable: false
      }
    ],
    items: [],
    itemsCoins: [],
    spinTheWheelData: {}
  }),
  methods: {
    getUser,

    async save(type) {
      let data = this[type === 2 ? 'items' : 'itemsCoins'];
      data = data
        .filter((item) => item.isActive)
        .map((item) => ({
          type,
          value: item.value
        }));

      const existingData = this.spinTheWheelData.web.filter(
        (item) => item.type === (type === 2 ? 3 : 2)
      );

      this.spinTheWheelData.web = [...data, ...existingData];

      if (
        this.spinTheWheelData.web.length < 3 ||
        this.spinTheWheelData.web.length > 7
      ) {
        return this.$toast.error(
          'Please select a minimum of 3 and a maximum of 7 items from spin the wheel (percentages) + Coins'
        );
      }

      const rearrangedArray = [];
      while (this.spinTheWheelData.web.length > 0) {
        rearrangedArray.push(this.spinTheWheelData.web.shift());
        rearrangedArray.push(
          this.spinTheWheelData.web.splice(
            this.spinTheWheelData.web.findIndex(
              (item) =>
                item.type !== rearrangedArray[rearrangedArray.length - 1].type
            ),
            1
          )[0]
        );
      }

      this.spinTheWheelData.web = rearrangedArray.filter(
        (item) => item !== undefined
      );

      await this.$axios.patch(
        '/app-status/spin-the-wheel-percentage-v2',
        this.spinTheWheelData
      );

      this.$toast.success('Spin the wheel percentages saved successfully');
    },

    async loadSpinTheWheelData(type) {
      const response = await this.$axios.get(
        '/app-status/spin-the-wheel-percentage-v2'
      );
      this.spinTheWheelData = response.data;

      let data = (this.spinTheWheelData.web || []).filter(
        (item) => item.type === type
      );

      const ranges = {
        2: { start: 10, end: 70, step: 10, additional: [5, 15, 25] },
        default: { start: 50, end: 350, step: 50, additional: [] }
      };

      const { start, end, step, additional } = ranges[type] || ranges.default;

      const finalItems = [];
      for (let i = start; i <= end; i += step) {
        const isActive = data.some((item) => item.value === i);
        finalItems.push({ value: i, isActive });
      }

      additional.forEach((value) => {
        if (!finalItems.some((item) => item.value === value)) {
          const isActive = data.some((item) => item.value === value);
          finalItems.push({ value, isActive });
        }
      });

      finalItems.sort((a, b) => a.value - b.value);

      return finalItems;
    },

    async loadData() {
      const data = await this.loadSpinTheWheelData(2);
      this.items = data;
      return data;
    },

    async loadDataCoins() {
      const data = await this.loadSpinTheWheelData(3);
      this.itemsCoins = data;
      return data;
    }
  }
};
</script>

<style scoped></style>
