var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"table",attrs:{"title":"Prices","loader":_vm.loadPrices,"headers":_vm.headers,"allow-add":false,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('ai-tutor-web-aps-prices:edit')
          ? _vm.editCoupon
          : null
        : null,"show-search":false},scopedSlots:_vm._u([{key:"coupon",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCouponName(item.metadata.coupon))+" ")]}},{key:"unit_amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.unit_amount / 100)+" ")]}}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticStyle:{"padding":"40px"}},[_c('v-form',{ref:"price"},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Coupon","clearable":"","items":_vm.coupons_list,"item-text":"label"},model:{value:(_vm.selectedCoupon),callback:function ($$v) {_vm.selectedCoupon=$$v},expression:"selectedCoupon"}}),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.saveCoupon}},[_vm._v(" Update price ")])],1)],1)],1),_c('loading-dialog',{attrs:{"message":_vm.loadingMessage},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('error-dialog',{attrs:{"error":_vm.errorValue},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }