var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
        _vm.getUser()
          ? _vm.getUser().scopes.includes('ai-tutor-web-aps-prices:view')
          : false
      )?_c('v-tab',[_vm._v("Packages")]):_vm._e(),(
        _vm.getUser()
          ? _vm.getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
          : false
      )?_c('v-tab',[_vm._v("Coupons")]):_vm._e(),(
        _vm.getUser()
          ? _vm.getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
          : false
      )?_c('v-tab',[_vm._v("Spin the Wheel")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
        _vm.getUser()
          ? _vm.getUser().scopes.includes('ai-tutor-web-aps-prices:view')
          : false
      )?_c('v-tab-item',[_c('package-view')],1):_vm._e(),(
        _vm.getUser()
          ? _vm.getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
          : false
      )?_c('v-tab-item',[_c('coupons-view')],1):_vm._e(),(
        _vm.getUser()
          ? _vm.getUser().scopes.includes('ai-tutor-web-aps-coupons:view')
          : false
      )?_c('v-tab-item',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"allow-add":false,"title":"Spin the Wheel"},scopedSlots:_vm._u([{key:"primary-action",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.save(2)}}},[_c('v-icon',[_vm._v("mdi-disk")]),_vm._v(" Save ")],1)]},proxy:true},{key:"web",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+"% ")]}},{key:"hide",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"label":"Active","color":"primary"},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}}],null,false,3959120924)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }