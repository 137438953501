var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('data-table',{key:_vm.dataTableKey,attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Offers","allow-add":_vm.getUser()
        ? _vm.getUser().scopes.includes('ai-tutor-iaps-offers:new')
        : false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('ai-tutor-iaps-offers:delete')
          ? _vm.deleteHandler
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('ai-tutor-iaps-offers:edit')
          ? _vm.edit
          : null
        : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"active",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":"","readonly":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"expiry",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expiry))+" ")]}}])}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.editLoading),callback:function ($$v) {_vm.editLoading=$$v},expression:"editLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }