<template>
  <v-card>
    <!--    <v-tabs v-model="tab">-->
    <!--      <v-tab v-if="getUser() ? getUser().scopes.includes('banners:view') : false">Banners</v-tab>-->
    <!--    </v-tabs>-->

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="getUser() ? getUser().scopes.includes('banners:view') : false"
      >
        <banners-view />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { getUser } from '@/utils/local';
import BannersView from '@/views/ads/BannersView';

export default {
  name: 'AdsView',
  components: { BannersView },
  data: () => ({
    tab: 0
  }),
  methods: {
    getUser
  }
};
</script>

<style scoped></style>
