import InAppPurchaseView from './InAppPurchaseView';
import { getUser } from '@/utils/local';
import InAppPurchaseOffersView from '@/views/offers_ai-tutor/InAppPurchaseOffersView';
import InAppPurchaseOffersForm from '@/views/offers_ai-tutor/InAppPurchaseOffersForm';
import AdsView from '@/views/ai-tutor_ads/AdsView';
import BannerForm from '@/views/ai-tutor_ads/BannerForm';
import WebAppPurchaseOffersView from '@/views/offers_ai-tutor/WebAppPurchaseOffersView.vue';
import PriceView from '@/views/offers_ai-tutor/PriceView.vue';

const AiTutorOffersRoutes = [
  getUser()?.scopes.includes('ai-tutor-iaps:view')
    ? {
        name: 'AITutorIAP',
        path: '/ai-tutor/iaps',
        component: InAppPurchaseView
      }
    : null,
  getUser()?.scopes?.includes('ai-tutor-ads:view')
    ? {
        name: 'AITutorAds',
        path: '/ai-tutor/ads',
        component: AdsView
      }
    : null,
  getUser()?.scopes?.includes('ai-tutor-ads:new')
    ? {
        name: 'AITutorBanner',
        path: '/ai-tutor/banner',
        component: BannerForm
      }
    : null,
  getUser()?.scopes.includes('ai-tutor-iaps-offers:view')
    ? {
        name: 'AITutorIapsOffers',
        path: '/ai-tutor/iaps/offers',
        component: InAppPurchaseOffersView
      }
    : null,
  getUser()?.scopes.includes('ai-tutor-iaps-offers:new') ||
  getUser()?.scopes.includes('ai-tutor-iaps-offers:edit')
    ? {
        name: 'AITutorNewIAPOffers',
        path: '/ai-tutor/iaps/offer',
        component: InAppPurchaseOffersForm
      }
    : null,
  getUser()?.scopes.includes('ai-tutor-web-aps:view')
    ? {
        name: 'AITutorWebApsOffers',
        path: '/ai-tutor/web-apps/offers',
        component: WebAppPurchaseOffersView
      }
    : null,

  getUser()?.scopes.includes('ai-tutor-web-aps:view')
    ? {
        name: 'AITutorPricesView',
        path: '/ai-tutor/web-apps/prices',
        component: PriceView
      }
    : null
];

export const aiTutorOffersRouter = AiTutorOffersRoutes.filter(function (x) {
  return x !== null;
});
