import { render, staticRenderFns } from "./UserForm.vue?vue&type=template&id=c0cc9160&scoped=true&"
import script from "./UserForm.vue?vue&type=script&lang=js&"
export * from "./UserForm.vue?vue&type=script&lang=js&"
import style0 from "./UserForm.vue?vue&type=style&index=0&id=c0cc9160&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0cc9160",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VTextField,VTreeview})
