import axios from 'axios';

export class PopupNotificationsService {
  async fetchAll() {
    return (await axios.get('/popup-notifications')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/popup-notifications/' + id)).data;
  }

  async create(data) {
    return (await axios.post('/popup-notifications', data)).data;
  }

  async delete(data) {
    return (await axios.delete('/popup-notifications/' + data.id)).data;
  }

  async update(data) {
    return (await axios.patch('/popup-notifications', data)).data;
  }

  async deletePopupNotificationButton(data) {
    return (await axios.delete(`/popup-notifications/${data.id}/${data.index}`))
      .data;
  }

  async fetchPopupStatsById(data) {
    const { id, page } = data;
    return (
      await axios.get(
        `/popup-notifications/pagination/stats/${id}?page=${page || 1}`
      )
    ).data;
  }
}
