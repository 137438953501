import axios from 'axios';

export class StudentGroupsService {
  async fetchAll() {
    return (await axios.get('/group-students')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/group-students/' + id)).data;
  }

  async fetchLeaderboardDetails(id) {
    return (await axios.get('/group-students/leaderboard/' + id)).data;
  }

  async create(group) {
    return (await axios.post('/group-students', group)).data;
  }

  async createBulkStudents(data) {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append(
      'jsonData',
      JSON.stringify({
        name: data.name,
        admins: data.admins
      })
    );
    return (
      await axios.post('/group-students/csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    ).data;
  }

  async delete(group) {
    return (await axios.delete('/group-students/' + group.id)).data;
  }

  async update(group) {
    return (await axios.patch('/group-students', group)).data;
  }
}
