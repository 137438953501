h5
<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row>
          <v-col class="d-flex justify-space-between align-center">
            <v-card-title style="color: #020819">
              Popup Notification Details</v-card-title
            >
            <v-btn @click="$router.back()" dark color="primary" elevation="0"
              >Back</v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            v-for="(content, index) in popupNotification.content"
            :key="index"
          >
            <v-card outlined>
              <v-img
                v-if="content.image"
                :src="content.image"
                width="100%"
                max-height="150px"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    style="background-color: #f5f5f5"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-card-title class="subtitle-1">
                {{ content.title }}
              </v-card-title>
              <v-card-text class="caption">
                {{ content.description }}</v-card-text
              >
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-6" no-gutters>
          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>One Time / Recurring : </b>

              <span class="ml-2">{{
                popupNotification.oncePerDay
                  ? 'Once per day'
                  : popupNotification.oneTime
                  ? 'One Time'
                  : 'Recurring'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Type : </b>

              <span class="ml-2">{{
                popupNotification.type === 0
                  ? 'All Users'
                  : popupNotification.type === 1
                  ? 'Specific Users'
                  : popupNotification.type === 2
                  ? 'Premium Users'
                  : ''
              }}</span>
            </v-card-text>
          </v-col>

          <v-col v-if="popupNotification.type === 1" cols="12">
            <v-card-text class="d-flex align-center">
              <b>Specific Users : </b>

              <span class="ml-2">{{
                popupNotification.specificUserList.join(', ')
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Start Date : </b>

              <span class="ml-2">{{
                formatDate(popupNotification.schedule.startDate)
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>End Date : </b>

              <span class="ml-2">{{
                formatDate(popupNotification.schedule.endDate)
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Active : </b>

              <span class="ml-2">{{
                popupNotification.active ? 'Active' : 'Inactive'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>IOS : </b>

              <span class="ml-2">{{
                popupNotification.ios ? 'Yes' : 'No'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Android : </b>

              <span class="ml-2">{{
                popupNotification.android ? 'Yes' : 'No'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Web : </b>

              <span class="ml-2">{{
                popupNotification.web ? 'Yes' : 'No'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Created At : </b>

              <span class="ml-2">{{
                popupNotification.createdAt
                  ? formatDate(popupNotification.createdAt)
                  : ''
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Updated At : </b>

              <span class="ml-2">{{
                popupNotification.updatedAt
                  ? formatDate(popupNotification.updatedAt)
                  : ''
              }}</span>
            </v-card-text>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <data-table
          :loader="loadData"
          :headers="headers"
          title="Popup Stats"
          :show-search="false"
          :hasElevation="false"
          :allow-add="false"
          allow-filters
          @filter="() => (isFilterDialog = true)"
          @done="$router.back()"
          :delete-handler="null"
          :edit-handler="null"
          :key="dataTableKey"
          :defaultFooter="false"
        >
          <template #date="{ item }">
            {{ formatDate(item.date) }}
          </template>

          <template
            #primary-action
            v-if="popupStats && popupStats.stats && popupStats.stats.length > 0"
          >
            <h5>Close Counts:</h5>
            <v-chip class="ma-1" label color="primary" small outlined>
              Android:
              {{ popupStats.stats[0].androidCloseClickCount }}</v-chip
            >

            <v-chip class="ma-1" label color="primary" small outlined>
              IOS:
              {{ popupStats.stats[0].iosCloseClickCount }}</v-chip
            >

            <v-chip class="ma-1" label color="primary" small outlined>
              Web:
              {{ popupStats.stats[0].webCloseClickCount }}</v-chip
            >
          </template>

          <template v-slot:custom-footer>
            <div class="v-data-footer d-flex justify-center align-center">
              <v-pagination
                :value="currentPage"
                class="my-2"
                @input="changePage"
                total-visible="10"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                :length="totalPages"
                outlined
              />
              <v-text-field
                v-model="pageInput"
                type="number"
                min="1"
                :max="totalPages"
                outlined
                dense
                hide-details
                style="max-width: 80px"
                placeholder="Page #"
                v-on:keydown.enter="changePage(parseInt(pageInput))"
              />
            </div>
          </template>
        </data-table>
      </div>
    </v-card>

    <v-dialog v-model="isFilterDialog" max-width="500">
      <v-card>
        <v-card-title>Filter By Button</v-card-title>
        <v-card-text>
          <v-select
            v-model="filterBy"
            :items="popupNotification.buttons"
            item-text="label"
            item-value="label"
            label="Button"
            multiple
            dense
            outlined
            hide-details
          ></v-select>
        </v-card-text>
        <v-card-actions
          style="justify-content: flex-end; padding: 16px 24px 16px 0"
        >
          <v-btn @click="isFilterDialog = false" color="primary" elevation="0"
            >Close</v-btn
          >
          <v-btn @click="dataTableKey++" color="primary" elevation="0">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { PopupNotificationsService } from '@/services/popup-notifications-service';
import DataTable from '../../components/DataTable';

export default {
  name: 'ResourceDetails',
  data() {
    return {
      dataTableKey: 0,
      dataLoading: true,
      service: new PopupNotificationsService(),

      popupNotification: {
        buttons: []
      },
      popupStats: null,
      isFilterDialog: false,
      filterBy: null,

      // data table pagination
      currentPage: 1,
      pageInput: 1,
      totalPages: 1,

      headers: [
        {
          text: 'Button',
          value: 'buttonLabel',
          sortable: true
        },
        {
          text: 'Date',
          value: 'date',
          sortable: true
        },
        {
          text: 'Android Open Count',
          value: 'androidOpenClickCount',
          sortable: true
        },
        {
          text: 'IOS Open Count',
          value: 'iosOpenClickCount',
          sortable: true
        },
        {
          text: 'Web Open Count',
          value: 'webOpenClickCount',
          sortable: true
        }
      ]
    };
  },

  components: {
    DataTable
  },

  async mounted() {
    this.dataLoading = true;
    await this.loadData();
    this.dataLoading = false;
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format('MMMM D, YYYY');
    },

    async loadData() {
      this.isFilterDialog = false;
      const popupId = this.$route.query.id;
      if (!popupId) {
        this.$router.push('/popup-notifications');
        return;
      }

      if (!this.filterBy) {
        this.popupNotification = await this.service.fetchOne(popupId);
      }

      // Fetch stats
      this.popupStats = await this.service.fetchPopupStatsById({
        id: popupId,
        page: this.currentPage
      });

      // Add button label to stats
      this.popupNotification.buttons.forEach((button, i) => {
        this.popupStats.stats.forEach((stat) => {
          if (stat.buttonIndex === i) {
            stat.buttonLabel = button.label;
          }
        });
      });

      // Sort stats by button index and date
      this.popupStats.stats = this.popupStats.stats.sort((a, b) => {
        if (a.buttonIndex === b.buttonIndex) {
          return new Date(b.date) - new Date(a.date);
        }
        return a.buttonIndex - b.buttonIndex;
      });

      // Filter stats by button
      if (this.filterBy) {
        this.popupStats.stats = this.popupStats.stats.filter((stat) =>
          this.filterBy.includes(stat.buttonLabel)
        );
      }

      this.totalPages = this.popupStats.total_pages;

      return this.popupStats.stats;
    },

    changePage(value) {
      this.currentPage = value;
      this.dataTableKey++;
    }
  }
};
</script>
<style scoped></style>
