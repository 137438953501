<template>
  <v-card>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="
          getUser() ? getUser().scopes.includes('ai-tutor-ads:view') : false
        "
      >
        <banners-view />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { getUser } from '@/utils/local';
import BannersView from '@/views/ai-tutor_ads/BannersView';

export default {
  name: 'AiTutorAdsView',
  components: { BannersView },
  data: () => ({
    tab: 0
  }),
  methods: {
    getUser
  }
};
</script>

<style scoped></style>
