<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Questions"
      :showSearch="false"
      :defaultFooter="false"
      :allow-add="false"
      allow-filters
      @filter="openFilter"
      @done="$router.back()"
      :delete-handler="null"
      :edit-handler="null"
      @click:row="handleEvent"
    >
      <template #primary-action>
        <div style="width: 400px; margin-right: 20px; max-width: 100%">
          <v-text-field
            v-model="search"
            solo
            label="Search by statement..."
            dense
            hide-details
            append-icon="mdi-magnify"
            @click:append="searchData"
            @change="searchData"
          />
        </div>
      </template>
      <template v-slot:category="{ item }">
        {{ item.category.name }}
      </template>
      <template v-slot:subCategory="{ item }">
        {{ item.subCategory.name }}
      </template>
      <template v-slot:createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:type="{ item }">
        {{ getType(item.type) }}
      </template>
      <template #trial="{ item }">
        <v-checkbox
          hide-details
          dense
          v-model="item.trial"
          @change="toggleTrial($event, item)"
          readonly
        />
      </template>
      <template #for_passage="{ item }">
        <v-checkbox hide-details dense v-model="item.for_passage" readonly />
      </template>

      <template v-slot:custom-footer>
        <div class="v-data-footer d-flex justify-center align-center">
          <v-pagination
            :value="currentPage + 1"
            class="my-2"
            @input="changePage"
            total-visible="10"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="totalPages + 1"
          />
          <v-text-field
            v-model="pageInput"
            type="number"
            min="1"
            :max="totalPages + 1"
            outlined
            dense
            hide-details
            style="max-width: 80px"
            placeholder="Page #"
            v-on:keydown.enter="changePage(parseInt(pageInput), true)"
          />
        </div>
      </template>
    </data-table>
    <v-dialog width="40%" v-model="showFilter">
      <v-card>
        <div v-if="!loading">
          <div class="d-flex items-center">
            <v-card-title>Filter By Field</v-card-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="showFilter = false" class="mt-n2 mr-4">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-form class="pa-5" ref="filter">
            <v-select
              ref="filter-fields"
              v-model="filter.fields"
              outlined
              dense
              label="Field"
              :items="fields"
              item-text="label"
              clearable
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  color="primary"
                  small
                  outlined
                  close
                  @click:close="filter.fields.splice(index, 1)"
                  class="mt-2 mb-1"
                >
                  {{ item.label }}
                </v-chip>
              </template>

              <template v-slot:append-item>
                <v-divider class="my-2"></v-divider>
                <div class="d-flex justify-end align-center mr-2">
                  <v-btn
                    elevation="0"
                    outlined
                    color="primary"
                    @click="$refs['filter-fields'].$refs.menu.isActive = false"
                    class="ml-auto"
                    >Done</v-btn
                  >
                </div>
              </template>
            </v-select>
            <div style="display: flex; gap: 20px">
              <v-select
                :rules="[required()]"
                :items="categories"
                return-object
                item-text="name"
                @change="getSubCategories"
                v-if="
                  (filter && filter.fields.includes('category')) ||
                  filter.fields.includes('subcategory')
                "
                v-model="filter.category"
                outlined
                dense
                label="Category"
              ></v-select>
              <v-select
                :rules="[required()]"
                :items="subCategories"
                return-object
                item-text="name"
                v-if="filter && filter.fields.includes('subcategory')"
                v-model="filter.subcategory"
                outlined
                dense
                label="Sub Category"
              ></v-select>
            </div>
            <v-select
              :rules="[required()]"
              v-if="filter && filter.fields.includes('explanation')"
              v-model="filter.explanation"
              outlined
              dense
              label="Has Explanation"
              :items="trials"
              item-text="label"
            ></v-select>
            <div style="display: flex; gap: 20px">
              <v-select
                :rules="[required()]"
                v-if="filter && filter.fields.includes('trial')"
                v-model="filter.trial"
                outlined
                dense
                label="Trial"
                :items="trials"
                item-text="label"
              ></v-select>
              <v-select
                :rules="[required()]"
                v-if="filter && filter.fields.includes('type')"
                v-model="filter.type"
                outlined
                dense
                label="Type"
                :items="types"
                item-text="label"
              ></v-select>
            </div>
            <v-select
              :rules="[required()]"
              v-if="filter && filter.fields.includes('passage')"
              v-model="filter.passage"
              outlined
              dense
              label="Passage"
              :items="trials"
              item-text="label"
            ></v-select>
            <v-select
              :rules="[required()]"
              v-if="filter && filter.fields.includes('user')"
              v-model="filter.user"
              outlined
              dense
              label="User"
              :items="users"
              item-text="username"
            ></v-select>
            <v-text-field
              :rules="[required()]"
              v-if="filter && filter.fields.includes('date')"
              v-model="filter.created"
              outlined
              dense
              type="date"
              label="Created At"
            ></v-text-field>
            <v-select
              :rules="[required()]"
              v-if="filter"
              v-model="filter.sort"
              outlined
              dense
              label="Sort"
              :items="orders"
              item-text="label"
            ></v-select>
            <div class="d-flex justify-end">
              <v-btn
                @click="filterClear"
                class="mr-2"
                elevation="0"
                outlined
                color="primary"
                >Clear</v-btn
              >
              <v-btn
                @click="applyFilter"
                class="ml-2"
                color="primary"
                elevation="0"
                >Apply</v-btn
              >
            </div>
          </v-form>
        </div>
        <div v-else class="pa-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate />
        </div>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loading" message="Loading..." />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable';
import { QuestionsService } from '@/services/questions-service';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import { CategoryService } from '@/services/category-service';
import { UsersService } from '@/services/users-service';
import LoadingDialog from '@/components/LoadingDialog';
import moment from 'moment';

export default {
  components: { LoadingDialog, DataTable },

  props: {
    isActiveCategory: {
      type: Boolean,
      default: true
    },
    questionViewModal: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    pageInput: '',
    loading: false,
    service: new QuestionsService(),
    categoryService: new CategoryService(),
    userService: new UsersService(),
    search: '',

    totalPages: 0,
    currentPage: 0,
    recordsPerPage: 10,

    headers: [
      {
        text: 'Question Statement',
        value: 'statement',
        sortable: false
      },
      {
        text: 'Category',
        value: 'categoryName',
        sortable: false
      },
      {
        text: 'Sub Category',
        value: 'subCategoryName'
      },
      {
        width: 100,
        text: 'Type',
        value: 'type'
      },
      {
        width: 100,
        text: 'Trial',
        value: 'trial'
      },
      {
        width: 100,
        text: 'Passage',
        value: 'for_passage'
      }
      // {
      //   text: 'Created At',
      //   value: 'createdAt'
      // }
    ],

    isFilter: false,

    showFilter: false,

    items: [],
    filter: {
      fields: [],
      sort: 'true'
    },
    fields: [
      {
        label: 'Category',
        value: 'category'
      },
      {
        label: 'Sub-Category',
        value: 'subcategory'
      },
      {
        label: 'Trial',
        value: 'trial'
      },
      {
        label: 'Type',
        value: 'type'
      },
      {
        label: 'Passage',
        value: 'passage'
      },
      {
        label: 'Explanation',
        value: 'explanation'
      },
      {
        label: 'Date',
        value: 'date'
      }
    ],
    types: [
      {
        label: 'True / False',
        value: 'bool'
      },
      {
        label: 'Multiple Choice',
        value: 'choices'
      }
    ],
    trials: [
      {
        label: 'Yes',
        value: 'true'
      },
      {
        label: 'No',
        value: 'false'
      }
    ],
    orders: [
      {
        label: 'Ascending',
        value: 'true'
      },
      {
        label: 'Descending',
        value: 'false'
      }
    ],

    categories: [],
    subCategories: [],
    users: [],

    showFileUpload: false,
    allSubs: [],
    uploadFile: null,
    isUploaded: false,
    isUploading: false,
    questions: [],
    importErrors: [],
    added: 0
  }),

  mounted() {
    this.getCategories();
    // this.getUsers()
  },

  methods: {
    getUser,
    required,

    refreshDataTable() {
      document.getElementById('searchDataReload').click();
    },

    changePage(value, byPageInput = false) {
      if (value > 0 && value <= this.totalPages + 1) {
        this.currentPage = value - 1;
      }
      if (byPageInput === false) {
        this.pageInput = '';
      }
      if (!this.search) {
        document.getElementById('refresh').click();
      } else {
        this.searchData();
      }
    },

    async searchData() {
      this.pageInput = '';
      await this.loadData(true);
      this.refreshDataTable();
    },

    async getUsers() {
      this.users = await this.userService.fetchAll();
    },

    async toggleTrial(value, item) {
      if (confirm('Warning! \n Are you sure?')) {
        this.loading = true;
        try {
          item.trial = value;
          await this.service.update(item);
        } catch (e) {
          console.error(e);
          this.$toast.error(
            'An error occurred while updating the trial status'
          );
        }
        this.loading = false;
      }
    },

    getType(type) {
      if (type === 'bool') {
        return 'True False';
      } else {
        return 'Multiple Choice';
      }
    },

    async loadData(search = false) {
      this.loading = true;

      if (this.isFilter) {
        await this.filterData();
      } else {
        let res;
        if (search) {
          res = await this.service.paginate(
            this.isActiveCategory,
            this.currentPage,
            this.recordsPerPage,
            this.search
          );
        } else {
          res = await this.service.paginate(
            this.isActiveCategory,
            this.currentPage,
            this.recordsPerPage
          );
        }
        this.items = res.data;
        this.totalPages = res.total_pages;
      }

      this.items.map((item) => {
        item.categoryName = this.categories.find(
          (category) => category.id === item.category
        ).name;
        item.subCategoryName = this.categories
          .find((category) => category.id === item.category)
          .subCategories.find((sub) => sub.id === item.subCategory).name;
      });
      this.loading = false;
      return this.items;
    },

    openFilter() {
      this.showFilter = true;
    },

    applyFilter() {
      if (this.$refs.filter.validate()) {
        this.isFilter = true;
        this.pageInput = '';
        this.currentPage = 0;
        document.getElementById('refresh').click();
      }
    },

    async filterData() {
      let query;
      query = 'sort_order=' + this.filter.sort;

      if (this.$refs.filter.validate()) {
        this.filter.fields.forEach((field) => {
          if (field === 'category') {
            query += '&type=0&category=' + this.filter.category.id;
          } else if (field === 'subcategory') {
            query += '&type=1&subcategory=' + this.filter.subcategory.id;
          } else if (field === 'trial') {
            query += '&type=2&trial=' + (this.filter.trial === 'true');
          } else if (field === 'type') {
            query += '&type=3&question-type=' + this.filter.type;
          } else if (field === 'date') {
            const day = new Date(this.filter.created);
            const startDate = moment(new Date(day)).format('YYYY-MM-DD');
            const nextDay = new Date(day);
            nextDay.setDate(day.getDate() + 1);
            const endDate = moment(new Date(nextDay)).format('YYYY-MM-DD');
            query += '&type=4&from=' + startDate + '&to=' + endDate;
          } else if (field === 'passage') {
            query += '&type=5&passage=' + (this.filter.passage === 'true');
          } else if (field === 'explanation') {
            query +=
              '&type=6&explanation=' + (this.filter.explanation === 'true');
          }
        });

        this.showFilter = false;
        let res = await this.service.paginate(
          this.isActiveCategory,
          this.currentPage,
          this.recordsPerPage,
          this.search,
          query
        );
        this.items = res.data;
        this.totalPages = res.total_pages;
        this.isFilter = true;
      }
    },
    filterClear() {
      this.$refs.filter.reset();
      this.filter = {
        fields: [],
        sort: 'true'
      };
      this.isFilter = false;
      this.showFilter = false;
      document.getElementById('refresh').click();
    },
    cancelFilter() {
      this.showFilter = false;
    },
    async getCategories() {
      this.categories = this.isActiveCategory
        ? await this.categoryService.fetchAll()
        : await this.categoryService.fetchAllInactive();
    },
    getSubCategories(item) {
      this.subCategories = item.subCategories;
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY - H:M');
    },
    handleEvent(item) {
      this.$emit('click:row', item);
    }
  }
};
</script>

<style scoped></style>
