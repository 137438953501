import axios from 'axios';

export class PassageQuestionsService {
  async fetchAll({
    inactiveCategory = false,
    category = null,
    subCategory = null,
    premium = false,
    active = true
  }) {
    return (
      await axios.get(
        '/passages?' +
          (inactiveCategory ? 'activeCategory=0' : 'activeCategory=1') +
          (category ? '&category=' + category : '') +
          (subCategory ? '&subCategory=' + subCategory : '') +
          (premium == 'true'
            ? '&premium=1'
            : premium === 'false'
            ? '&premium=0'
            : '') +
          (active == true ? '&active=1' : active === false ? '&active=0' : '')
      )
    ).data;
  }

  async fetchByCategory(category, subcategory) {
    return (
      await axios.get(
        '/passages?category=' +
          category +
          (subcategory ? '&subCategory=' + subcategory : '')
      )
    ).data;
  }

  async fetchPassageQuestionsOfTheDay() {
    return (await axios.get('/passages/passage-of-the-days-populate')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/passages/' + id)).data;
  }

  async getStats(id) {
    return (await axios.get('/passages/stats?id=' + id)).data;
  }

  async create(passage) {
    return (await axios.post('/passages', passage)).data;
  }

  async delete(passage) {
    return (await axios.delete('/passages/' + passage.id)).data;
  }

  async update(passage) {
    return (await axios.patch('/passages', passage)).data;
  }

  async updatePassageOfTheDay(index, id) {
    // return (await axios.patch('/passages/passage-of-the-days', passage)).data;
    return (
      await axios.put(
        '/passages/passage-of-the-days/' + index + '/replace-with/' + id
      )
    ).data;
  }
}
