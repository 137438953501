<template>
  <div>
    <v-app-bar app dark color="primary" elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-card-title>King of the Curve</v-card-title>
      <v-spacer />

      <profile-popup />
    </v-app-bar>
    <v-main class="main-bg">
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-navigation-drawer v-model="drawer" app>
      <img src="../assets/logo.png" alt="logo" class="logo" height="70" />
      <v-divider />
      <template v-for="(route, key) in routes">
        <v-list-item
          v-if="route.isVisible"
          :key="key"
          class="route"
          :class="isActive(route) && 'route--active'"
          active-class="route--active"
          exact
          dense
          :to="route.to"
        >
          <v-list-item-icon>
            <v-icon>
              {{ route.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ route.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-else-if="route.isDivider"
          :key="key + 'divider'"
          style="margin: 10px 20px"
        />
      </template>
    </v-navigation-drawer>
    <LoadingDialog v-model="loading" message="Loading..." />
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="snackbarText !== 'Loading...'"
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ProfilePopup from './ProfilePopup';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import LoadingDialog from '@/components/LoadingDialog';

export default {
  name: 'Dashboard',
  components: { LoadingDialog, ProfilePopup },
  data: () => ({
    drawer: true,
    user: {},
    message: null,
    loading: false,
    snackbar: false,
    snackbarText: 'Loading...'
  }),
  mounted() {
    this.user = this.getUser();
  },
  methods: {
    getUser,
    required,

    isActive(route) {
      const currentRoute = this.$route.path;
      return (
        route.subRoutes &&
        route.subRoutes.length > 0 &&
        route.subRoutes.includes(currentRoute)
      );
    }
  },
  computed: {
    routes() {
      return [
        {
          to: '/settings',
          title: 'Settings',
          icon: 'mdi-tune',
          isVisible: this.getUser()?.scopes?.includes('settings:view')
        },
        this.getUser()?.scopes?.includes('events:view') ||
        this.getUser()?.scopes?.includes('facts:view') ||
        this.getUser()?.scopes?.includes('notifications:view')
          ? { isDivider: true }
          : { isDivider: false },
        {
          to: '/',
          title: 'Home',
          icon: 'mdi-home',
          isVisible: true
        },
        this.getUser()?.scopes?.includes('events:view') ||
        this.getUser()?.scopes?.includes('facts:view') ||
        this.getUser()?.scopes?.includes('notifications:view')
          ? { isDivider: true }
          : { isDivider: false },
        {
          to: '/events',
          subRoutes: ['/event'],
          title: 'Events',
          icon: 'mdi-gesture-tap',
          isVisible: this.getUser()?.scopes?.includes('events:view')
        },
        {
          to: '/notifications',
          subRoutes: ['/notification'],
          title: 'Notifications',
          icon: 'mdi-bell',
          isVisible: this.getUser()?.scopes?.includes('notifications:view')
        },
        {
          to: '/popup-notifications',
          subRoutes: ['/popup-notification', '/popup-notification-details'],
          title: 'Popup Notifications',
          icon: 'mdi-bell',
          isVisible: this.getUser()?.scopes?.includes(
            'popup-notifications:view'
          )
        },
        {
          to: '/facts',
          subRoutes: ['/fact'],
          title: 'Facts',
          icon: 'mdi-card-bulleted',
          isVisible: this.getUser()?.scopes?.includes('facts:view')
        },
        this.getUser()?.scopes?.includes('offers:view') ||
        this.getUser()?.scopes?.includes('iaps:view')
          ? { isDivider: true }
          : { isDivider: false },
        {
          to: '/ads',
          subRoutes: ['/banner'],
          title: 'Banners (New)',
          icon: 'mdi-tag',
          isVisible: this.getUser()?.scopes?.includes('ads:view')
        },
        {
          to: '/offers',
          title: 'Offers (Ads)',
          icon: 'mdi-tag',
          isVisible:
            this.getUser()?.scopes?.includes('banners:view') ||
            this.getUser()?.scopes?.includes('popups:view')
        },
        {
          to: '/iaps',
          subRoutes: ['/iaps/offers', '/iaps/offer'],
          title: 'In App Purchase',
          icon: 'mdi-cash',
          isVisible: this.getUser()?.scopes?.includes('iaps:view')
        },
        {
          to: '/web-apps/offers',
          subRoutes: ['/web-apps/offers', '/web-apps/prices'],
          title: 'Web App Purchase',
          icon: 'mdi-cash',
          isVisible: this.getUser()?.scopes?.includes('web-aps:view')
        },
        this.getUser()?.scopes?.includes('ai-tutor-iaps:view')
          ? { isDivider: true }
          : { isDivider: false },
        {
          to: '/ai-tutor/ads',
          subRoutes: ['/ai-tutor/banner'],
          title: 'Banners (AI Tutor)',
          icon: 'mdi-tag',
          isVisible: this.getUser()?.scopes?.includes('ai-tutor-ads:view')
        },
        {
          to: '/ai-tutor/iaps',
          subRoutes: ['/ai-tutor/iaps/offers', '/ai-tutor/iaps/offer'],
          title: 'In App Purchase (AI Tutor)',
          icon: 'mdi-cash',
          isVisible: this.getUser()?.scopes?.includes('ai-tutor-iaps:view')
        },
        {
          to: '/ai-tutor/web-apps/offers',
          subRoutes: ['/ai-tutor/web-apps/offers', '/ai-tutor/web-apps/prices'],
          title: 'Web App Purchase (AI Tutor)',
          icon: 'mdi-cash',
          isVisible: this.getUser()?.scopes?.includes('ai-tutor-web-aps:view')
        },
        {
          to: '/ai-tutor/stats',
          title: 'AI Tutor Stats',
          icon: 'mdi-chart-areaspline',
          isVisible: this.getUser()?.scopes?.includes('ai-tutor-stats:view')
        },
        this.getUser()?.scopes?.includes('questions:view') ||
        this.getUser()?.scopes?.includes('questions-old:view') ||
        this.getUser()?.scopes?.includes('flagged-questions:view') ||
        this.getUser()?.scopes?.includes('categories:view')
          ? { isDivider: true }
          : { isDivider: false },
        {
          to: '/institutions',
          title: 'Institutions',
          icon: 'mdi-town-hall',
          isVisible: this.getUser()?.scopes?.includes('institutions:view')
        },
        {
          to: '/questions',
          subRoutes: [
            '/question',
            '/question-details',
            '/question/comment',
            '/questions/ideas'
          ],
          title: 'Questions',
          icon: 'mdi-head-question',
          isVisible: this.getUser()?.scopes?.includes('questions:view')
        },
        {
          to: '/comments',
          title: 'Comments',
          icon: 'mdi-message',
          isVisible: this.getUser()?.scopes?.includes('comments:view')
        },
        {
          to: '/flagged-questions',
          title: 'Flagged Questions',
          icon: 'mdi-flag',
          isVisible: this.getUser()?.scopes?.includes('flagged-questions:view')
        },
        {
          to: '/passage-questions',
          subRoutes: ['/passage-question', '/passage-questions/ideas'],
          title: 'Passage Questions',
          icon: 'mdi-format-paragraph',
          isVisible: this.getUser()?.scopes?.includes('passage-questions:view')
        },
        {
          to: '/passage-prompts',
          subRoutes: ['/passage-prompt'],
          title: 'Passage Prompts',
          icon: 'mdi-format-paragraph',
          isVisible: this.getUser()?.scopes?.includes('passage-prompts:view')
        },
        {
          to: '/passage-questions-of-the-day',
          subRoutes: ['/passage-question-of-the-day'],
          title: 'Passage Questions OTD',
          icon: 'mdi-format-paragraph',
          isVisible: this.getUser()?.scopes?.includes('passage-questions:view')
        },
        {
          to: '/categories',
          subRoutes: ['/category'],
          title: 'Categories',
          icon: 'mdi-format-list-bulleted',
          isVisible: this.getUser()?.scopes?.includes('categories:view')
        },
        {
          to: '/sub-categories',
          subRoutes: ['/sub-category'],
          title: 'Sub Categories',
          icon: 'mdi-format-list-bulleted',
          isVisible: this.getUser()?.scopes?.includes('sub-categories:view')
        },
        {
          to: '/question-medias',
          subRoutes: ['/question-media'],
          title: 'Question Media',
          icon: 'mdi-image',
          isVisible: this.getUser()?.scopes?.includes('question-media:view')
        },
        {
          to: '/user-contributions',
          subRoutes: ['/user-contribution'],
          title: 'User Contributions',
          icon: 'mdi-head-question',
          isVisible: this.getUser()?.scopes?.includes('user-contributions:view')
        },

        this.getUser()?.scopes?.includes('media:view') && { isDivider: true },
        {
          to: '/media',
          title: 'Media',
          icon: 'mdi-folder-multiple-image',
          isVisible: this.getUser()?.scopes?.includes('media:view')
        },
        {
          to: '/video-section-modal',
          subRoutes: ['/video-sections'],
          title: 'Video Section',
          icon: 'mdi-video',
          isVisible: this.getUser()?.scopes?.includes('video:view')
        },
        this.getUser()?.scopes?.includes('decks:view') ||
        this.getUser()?.scopes?.includes('flashcards:view')
          ? { isDivider: true }
          : { isDivider: false },
        {
          to: '/decks',
          subRoutes: ['/deck'],
          title: 'Decks',
          icon: 'mdi-cards',
          isVisible: this.getUser()?.scopes?.includes('decks:view')
        },
        {
          to: '/flashcards',
          subRoutes: ['/flashcard'],
          title: 'Flashcards',
          icon: 'mdi-lightning-bolt-circle',
          isVisible: this.getUser()?.scopes?.includes('flashcards:view')
        },

        this.getUser()?.scopes?.includes('roadmap-flashcards:view') ||
        this.getUser()?.scopes?.includes('roadmap-chapters:view')
          ? { isDivider: true }
          : { isDivider: false },

        {
          to: '/roadmap-chapters',
          subRoutes: ['/roadmap-chapter'],
          title: 'Roadmap Chapters',
          icon: 'mdi-book-open-page-variant',
          isVisible: this.getUser()?.scopes?.includes('roadmap-chapters:view')
        },

        {
          to: '/roadmap-flashcards',
          subRoutes: ['/roadmap-flashcard'],
          title: 'Roadmap Flashcards',
          icon: 'mdi-lightning-bolt-circle',
          isVisible: this.getUser()?.scopes?.includes('roadmap-flashcards:view')
        },
        {
          to: '/roadmap-flagged-questions',
          title: 'Roadmap Flagged Questions',
          icon: 'mdi-flag',
          isVisible: this.getUser()?.scopes?.includes(
            'roadmap-flagged-questions:view'
          )
        },
        {
          to: '/roadmap-suggestions',
          title: 'Roadmap Suggestions',
          icon: 'mdi-flag',
          isVisible: this.getUser()?.scopes?.includes('roadmap-suggestion:view')
        },
        {
          to: '/roadmap-stats',
          title: 'Roadmap Stats',
          icon: 'mdi-chart-areaspline',
          isVisible: this.getUser()?.scopes?.includes('roadmap-stats:view')
        },
        this.getUser()?.scopes?.includes('users:view') ||
        this.getUser()?.scopes?.includes('reviews:view')
          ? { isDivider: true }
          : { isDivider: false },
        {
          to: '/users',
          subRoutes: ['/user'],
          title: 'Users',
          icon: 'mdi-account-group',
          isVisible: this.getUser()?.scopes?.includes('users:view')
        },
        {
          to: '/stats',
          subRoutes: ['/stats'],
          title: 'Stats',
          icon: 'mdi-chart-areaspline',
          isVisible: this.getUser()?.scopes?.includes('stats:view')
        },
        //TODO : check is visible
        {
          to: '/question-stats#1',
          title: 'Question Stats',
          icon: 'mdi-chart-areaspline',
          isVisible: this.getUser()?.scopes?.includes('stats:view')
        },
        {
          to: '/account-deletion-requests',
          title: 'Account Deletion Requests',
          icon: 'mdi-account-group',
          isVisible: this.getUser()?.scopes?.includes('account-deletion:view')
        },
        {
          to: '/daily-bonus',
          subRoutes: ['/bonus'],
          title: 'Daily Bonus/Currency',
          icon: 'mdi-gift',
          isVisible: this.getUser()?.scopes?.includes('daily-bonus:view')
        },
        {
          to: '/coins-leaderboard',
          title: 'Coins Leaderboard',
          icon: 'mdi-chart-bar',
          isVisible: this.getUser()?.scopes?.includes('coins-leaderboard:view')
        },
        {
          to: '/scheduled-qotds',
          subRoutes: ['/scheduled-qotd'],
          title: 'Scheduled QOTD',
          icon: 'mdi-calendar-clock',
          isVisible: this.getUser()?.scopes?.includes('scheduledQotd:view')
        },
        {
          to: '/customers',
          subRoutes: ['/customer-detail', '/customers'],
          title: 'Customers',
          icon: 'mdi-account-group',
          isVisible: this.getUser()?.scopes?.includes('customers:view')
        },
        {
          to: '/student-groups',
          subRoutes: ['/student-groups/leaderboard', '/student-group'],
          title: 'Student Groups',
          icon: 'mdi-account-group',
          isVisible: this.getUser()?.scopes?.includes('studentGroups:view')
        },
        {
          to: '/reviews',
          title: 'Reviews',
          icon: 'mdi-message-draw',
          isVisible: this.getUser()?.scopes?.includes('reviews:view')
        },
        {
          to: '/promo-refers',
          title: 'Promo Refers',
          icon: 'mdi-sale',
          isVisible: this.getUser()?.scopes?.includes('promoRefer:view')
        }
      ];
    }
  }
};
</script>

<style lang="sass" scoped>
.logo
  display: block
  margin: 5px auto
  border-radius: 10px

.route
  margin: 5px
  text-align: left
  overflow: hidden
  border-radius: 4px
  font-size: 20px !important
  font-family: "Roboto", sans-serif

  i
    font-size: 20px

  &--active
    background: lighten(#495db7,44%) !important
    color: #495db7 !important

  &--active i
    color: inherit

.main-bg
  background: linear-gradient(to bottom, #1976d2 400px, white 300px)
</style>
