<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      <h2 v-if="isView" class="text-h5 my-1 font-weight-medium">View Banner</h2>
      <h2 v-else class="text-h5 my-1 font-weight-medium">
        {{ isEdit ? 'Update Banner' : 'Create New Banner' }}
      </h2>
    </v-card-title>
    <v-form ref="banner" class="pa-5">
      <div class="d-flex justify-center align-end">
        <image-upload
          :hasError="showImageError"
          :image_obj="old_image"
          class="mx-auto span-2"
          @uploadedImage="getUploadedImage"
          :disabled="isView"
        />
      </div>

      <div class="d-flex flex-wrap">
        <v-checkbox
          style="width: 100%"
          dense
          :readonly="isView"
          hide-details
          v-model="active"
          label="Active"
        />
        <v-checkbox
          style="width: 30%"
          dense
          :readonly="isView"
          hide-details
          v-model="web"
          label="For Web"
        />
        <v-checkbox
          style="width: 40%"
          v-model="android"
          dense
          :readonly="isView"
          label="For Android"
          hide-details
        />
        <v-checkbox
          style="width: 30%"
          v-model="ios"
          dense
          :readonly="isView"
          label="For iOS"
          hide-details
        />

        <div style="width: 100%" class="d-flex mt-4">
          <v-text-field
            style="width: 50%"
            class="mr-2"
            v-model="title"
            :readonly="isView"
            outlined
            dense
            label="Title"
            :rules="[required()]"
          />

          <v-select
            v-model="action"
            style="width: 50%"
            class="ml-2"
            outlined
            dense
            :readonly="isView"
            label="Action"
            :items="actions"
            item-text="label"
            :rules="[required()]"
          />
        </div>
        <v-text-field
          v-if="action === 10"
          v-model="link"
          dense
          :readonly="isView"
          outlined
          label="Action Link"
          :rules="[required()]"
        />
      </div>

      <div
        class="span-2 d-flex"
        v-for="(page, index) in placementPages"
        :key="index"
      >
        <span class="ma-2 ml-0" style="width: 100%">
          {{ page.label }}
        </span>

        <span
          v-if="isView"
          class="ma-2 ml-0"
          style="width: 100%; white-space: nowrap"
        >
          <b>Clicks :</b> {{ placements[index]['click_count'] }}
        </span>

        <v-select
          v-model="placements[index]['position']"
          class="ma-2"
          :error="
            !placements[index]['position'] && placements[index]['duration'] > 0
          "
          style="width: 100%"
          outlined
          v-if="!isView"
          hide-details
          clearable
          @click:clear="placements[index]['duration'] = 0"
          dense
          label="Placement Position"
          :items="
            placements[index]['page'] === 9
              ? placementPositionsForHome
              : placementPositions
          "
          item-text="label"
        >
        </v-select>
        <span v-else class="ma-2 ml-0" style="width: 100%; white-space: nowrap">
          <b>Position :</b>
          {{
            placements[index]['position']
              ? getBannerPosition(placements[index]['position'])
              : '-----'
          }}
        </span>

        <v-text-field
          v-if="!isView"
          v-model="placements[index]['duration']"
          class="ma-2 mr-0"
          :rules="[(val) => val >= 0]"
          min="0"
          :error="
            placements[index]['position'] && placements[index]['duration'] <= 0
          "
          style="width: 100%"
          outlined
          hide-details
          dense
          label="Duration (Seconds)"
          type="number"
        />
        <span v-else class="ma-2 ml-0" style="width: 100%; white-space: nowrap">
          <b>Duration :</b>
          {{
            placements[index]['duration'] !== 0
              ? placements[index]['duration']
              : '-----'
          }}
        </span>
      </div>
      <div style="width: 100%" class="text-center my-5">
        <v-btn
          v-if="!isView"
          :disabled="disabled"
          elevation="0"
          class="px-10"
          color="primary"
          @click="saveBanner"
          >Submit
        </v-btn>
      </div>
    </v-form>

    <loading-dialog v-model="loading" :message="loadingMessage" />
    <error-dialog v-model="error" :error="errorValue" />
  </v-card>
</template>

<script>
import { AdvertisementsService } from '@/services/advertisements-service';
import { required, requiredV2 } from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { storage } from '@/plugins/firebase';
import { getBannerPosition } from '@/utils/local';
import ImageUpload from '@/components/ImageUpload';

export default {
  name: 'BannerForm',
  components: { ErrorDialog, LoadingDialog, ImageUpload },

  data: () => ({
    error: false,
    errorValue: {},

    showImageError: false,
    disabled: false,

    id: null,
    title: '',
    image: null,
    displayImage: null,
    isEdit: false,
    isView: false,
    action: '',
    link: '',
    active: true,
    web: true,
    ios: true,
    android: true,
    placementPages: [
      {
        label: 'Friends',
        value: 1
      },
      {
        label: 'Matches',
        value: 2
      },
      {
        label: 'Question Page',
        value: 3
      },
      {
        label: 'Multiplayer Menu',
        value: 4
      },
      {
        label: 'Single Player Menu',
        value: 5
      },
      {
        label: 'My Stats',
        value: 6
      },
      {
        label: 'Your Answer',
        value: 7
      },
      {
        label: 'Endless Menu',
        value: 8
      },
      {
        label: 'Home Page',
        value: 9
      },
      {
        label: 'Adaptive Bank Menu',
        value: 10
      },
      {
        label: 'Game over',
        value: 11
      },
      {
        label: 'Review Mode Menu',
        value: 12
      },
      {
        label: 'Flashcards',
        value: 13
      },
      {
        label: 'Boolean Blitz',
        value: 14
      },
      {
        label: 'Question of the day',
        value: 15
      },
      {
        label: 'Timed Menu',
        value: 16
      },
      {
        label: 'Compete Page',
        value: 17
      },
      {
        label: 'Training Page',
        value: 18
      },
      {
        label: 'Review Page',
        value: 19
      },
      {
        label: 'Study Page',
        value: 20
      }
    ],
    actions: [
      {
        label: 'Show Subscription Packages',
        value: 1
      },
      {
        label: 'Play Endless Mode',
        value: 2
      },
      {
        label: 'Play Adaptive Qbank',
        value: 3
      },
      {
        label: 'Spin to get Random Discount',
        value: 4
      },
      {
        label: 'Play Multiplayer',
        value: 5
      },
      {
        label: 'Play Review Mode',
        value: 6
      },
      {
        label: 'Link Institution',
        value: 7
      },
      {
        label: 'Play Timed Mode',
        value: 8
      },
      {
        label: 'Share Feedback',
        value: 9
      },
      {
        label: 'Open Link',
        value: 10
      }
    ],
    placementPositions: [
      {
        label: 'Top',
        value: 1
      },
      {
        label: 'Bottom',
        value: 2
      },
      {
        label: 'All',
        value: 3
      }
    ],
    placementPositionsForHome: [
      {
        label: 'Top',
        value: 1
      },
      {
        label: 'Bottom',
        value: 2
      },
      {
        label: 'All',
        value: 3
      },
      {
        label: 'Above Videos of the Day',
        value: 4
      },
      {
        label: 'Above Images of the Day',
        value: 5
      },
      {
        label: 'Above Personalize My Study',
        value: 6
      },
      {
        label: 'Above Predictive Stats Score',
        value: 7
      },
      {
        label: 'Above Spin the Wheel',
        value: 8
      }
    ],
    loading: false,
    loadingMessage: '',
    service: new AdvertisementsService(),
    placements: []
  }),

  computed: {
    old_image() {
      return {
        url: this.displayImage
      };
    }
  },

  async mounted() {
    this.placements = [
      ...this.placementPages.map((page) => {
        return {
          page: page.value,
          position: null,
          duration: 0,
          click_count: 0,
          ios_click_count: 0,
          android_click_count: 0,
          web_click_count: 0
        };
      })
    ];

    if (this.$route.query.id) {
      if (this.$route.query.edit) {
        this.isEdit = true;
      } else {
        this.isView = true;
      }
      await this.service.fetchOne(this.$route.query.id).then((response) => {
        this.id = response.id;
        this.title = response.title;
        this.displayImage = response.image;
        this.action = response.action;
        this.link = response.link;
        this.active = response.active;
        this.web = !!response.web;
        this.ios = response.ios;
        this.android = response.android;
        this.placements.forEach((placement) => {
          const found = response.placements.find(
            (p) => p.page === placement.page
          );
          if (found) {
            placement.position = found.position;
            placement.duration = found.duration;
            placement.click_count = found.click_count;
            placement.ios_click_count =
              found.ios_click_count - found.click_count;
            placement.android_click_count = found.android_click_count;
            placement.web_click_count = found.web_click_count;
          }
        });
      });
    }
  },

  methods: {
    requiredV2,
    required,
    getBannerPosition,

    getUploadedImage(_image_obj) {
      if (_image_obj) {
        this.image = _image_obj.file;
        this.showImageError = false;
        this.disabled = false;
      } else {
        this.showImageError = true;
        this.disabled = true;
      }
    },

    customValidate() {
      return (
        this.placements.filter((placement) => {
          if (placement.position && placement.duration <= 0) {
            return true;
          }
          if (!placement.position && placement.duration > 0) {
            return true;
          }

          return false;
        }).length === 0
      );
    },

    async saveBanner() {
      if (this.$refs.banner.validate()) {
        let valid = this.customValidate();
        if (!valid) return;

        try {
          if (!this.isEdit) {
            // validate Image
            if (!this.image) {
              this.showImageError = true;
              return false;
            }

            this.loadingMessage = 'Creating New Banner';
          } else {
            this.loadingMessage = 'Updating Banner';
          }
          this.loading = true;

          if (this.image) {
            const fileName = this.image.name.split('.')[0];

            // Upload File
            let reference = storage.ref('ads/banners/' + fileName);
            await reference.put(this.image);

            // Get download url from firebase storage and add to newMedia object
            this.displayImage = await storage
              .ref('ads/banners/')
              .child(fileName)
              .getDownloadURL();
          }

          const obj = {
            title: this.title,
            image: this.displayImage || '',
            placements: this.placements
              .filter((placement) => placement.position)
              .map((placement) => {
                return {
                  ...placement,
                  duration: parseInt(placement.duration)
                };
              }),
            action: this.action,
            link: this.link,
            active: this.active,
            web: this.web,
            ios: this.ios,
            android: this.android
          };

          if (this.isEdit) {
            obj.id = this.id;
            await this.service.update(obj);
          } else {
            await this.service.create(obj);
          }

          this.$refs.banner.reset();
          this.loading = false;
          this.isEdit = false;

          await this.$router.push('/ads');
        } catch (e) {
          this.loading = false;
          this.error = true;
          this.errorValue = {
            title: `Error while ${
              this.isEdit ? 'Updating' : 'Creating'
            } Banner`,
            description: e?.data?.error ?? 'Some error occurred'
          };
        }
      }
    }
  }
};
</script>
