<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Popup Notifications"
      :allow-add="
        getUser() ? getUser().scopes.includes('popup-notifications:new') : false
      "
      @add-new="addNew"
      @done="$router.back()"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('popup-notifications:delete')
            ? deleteItem
            : null
          : null
      "
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('popup-notifications:edit')
            ? edit
            : null
          : null
      "
      :view-handler="view"
    >
      <template #title="{ item }">
        <span>{{
          item.content[0].title.length > 50
            ? item.content[0].title.substring(0, 50) + '...'
            : item.content[0].title
        }}</span>
      </template>

      <template #startDate="{ item }">
        <span>{{ formatDate(item.schedule.startDate) }}</span>
      </template>

      <template #endDate="{ item }">
        <span>{{ formatDate(item.schedule.endDate) }}</span>
      </template>

      <template #ios="{ item }">
        <v-checkbox
          v-model="item.ios"
          dense
          hide-details
          @click="updateItem(item)"
        />
      </template>

      <template #android="{ item }">
        <v-checkbox
          v-model="item.android"
          dense
          hide-details
          @click="updateItem(item)"
        />
      </template>

      <template #web="{ item }">
        <v-checkbox
          v-model="item.web"
          dense
          hide-details
          @click="updateItem(item)"
        />
      </template>

      <template #active="{ item }">
        <v-switch
          v-model="item.active"
          @click="updateItem(item)"
          hide-details
          class="mt-0"
        />
      </template>
    </data-table>

    <loading-dialog v-model="loading" message="Updating..." />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { getUser } from '@/utils/local';
import { PopupNotificationsService } from '@/services/popup-notifications-service';
import dayjs from 'dayjs';
import { storage } from '@/plugins/firebase';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    loading: false,
    service: new PopupNotificationsService(),

    headers: [
      {
        text: 'Index',
        value: 'index'
      },
      {
        text: 'Title',
        value: 'title'
      },
      {
        text: 'Start Date',
        value: 'startDate'
      },
      {
        text: 'End Date',
        value: 'endDate'
      },
      {
        text: 'IOS',
        value: 'ios'
      },
      {
        text: 'Android',
        value: 'android'
      },
      {
        text: 'Web',
        value: 'web'
      },
      {
        text: 'Active',
        value: 'active'
      }
    ]
  }),

  methods: {
    getUser,

    formatDate(date) {
      return dayjs(date).format('MMMM D, YYYY');
    },
    edit(item) {
      this.$router.push(`/popup-notification?id=${item.id}`);
    },
    async deleteItem(item) {
      for (let i = 0; i < item.content.length; i++) {
        if (item.content[i].image) {
          await storage.refFromURL(item.content[i].image).delete();
        }
      }
      await this.service.delete(item);
    },
    addNew() {
      this.$router.push('/popup-notification');
    },
    view(item) {
      this.$router.push(`/popup-notification-details?id=${item.id}`);
    },
    async loadData() {
      let data = await this.service.fetchAll();

      data = data.sort((a, b) => {
        return a.active === b.active ? 0 : a.active ? -1 : 1;
      });

      return data;
    },
    async updateItem(item) {
      try {
        this.loading = true;
        await this.service.update(item);
        this.$toast.success('Popup notification updated successfully');
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
