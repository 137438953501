<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <div class="span-2 d-flex align-center justify-space-between">
      <p class="span-2 form__title">
        {{ isEdit ? 'Update Popup Notification' : 'Create Popup Notification' }}
      </p>

      <v-btn
        @click="$router.back()"
        dark
        color="primary"
        elevation="0"
        class="mb-8 d-none d-md-block"
      >
        Back
      </v-btn>
    </div>

    <v-switch
      v-model="popupNotification.active"
      :label="popupNotification.active ? 'Active' : 'Inactive'"
      class="span-2 ml-auto"
    />

    <v-row class="span-2 px-4 mb-2 d-flex justify-space-between">
      <v-checkbox v-model="popupNotification.ios" label="iOS" />
      <v-checkbox v-model="popupNotification.android" label="Android" />
      <v-checkbox v-model="popupNotification.web" label="Web" />
    </v-row>

    <v-select
      v-if="isEdit"
      v-model="popupNotification.index"
      :items="indexes"
      label="Index"
      outlined
      persistent-hint
      class="span-2"
    />

    <div class="span-2 mb-8">
      <v-card
        v-for="(content, index) in popupNotification.content"
        :key="index"
        outlined
        class="mb-4"
      >
        <v-card-title>
          <p class="span-2">Content {{ index + 1 }}</p>
          <v-btn
            v-if="index !== 0"
            icon
            @click="popupNotification.content.splice(index, 1)"
            class="ml-auto"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="content.title"
            label="Title"
            dense
            outlined
            persistent-hint
            :rules="[required()]"
            class="span-2"
          />
          <v-textarea
            v-model="content.description"
            label="Description"
            dense
            outlined
            persistent-hint
            :rules="[required()]"
            class="span-2"
          />

          <v-img
            v-if="content.image"
            :src="content.image"
            max-height="200px"
            contain
            class="span-2 mb-6 rounded-lg"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                style="background-color: #f5f5f5"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-file-input
            v-model="content.imageFile"
            label="Upload Image"
            outlined
            accept="image/*"
            @change="previewImage(content)"
            @click:clear="content.image = ''"
          >
          </v-file-input>
        </v-card-text>
      </v-card>
      <v-btn
        elevation="0"
        color="green"
        dark
        small
        class="d-flex ml-auto"
        @click="
          popupNotification.content.push({
            title: '',
            description: '',
            image: ''
          })
        "
      >
        <v-icon small class="mr-1 ml-n2">mdi-plus</v-icon>
        Add Content
      </v-btn>
    </div>

    <v-select
      v-model="popupNotification.notificationTime"
      :items="[
        { text: 'One Time', value: true },
        { text: 'Recurring Until Answer', value: false },
        { text: 'Once Per Day', value: 'oncePerDay' }
      ]"
      label="Schedule Type"
      outlined
      persistent-hint
      class="span-2"
      @change="handleNotificationTime"
    />

    <v-select
      v-model="popupNotification.type"
      :items="popupNotificationTypes"
      label="User Type"
      outlined
      persistent-hint
      class="span-2"
    />

    <div class="span-2 mb-5">
      <v-chip
        v-for="(user, index) in popupNotification.specificUserList"
        :key="index"
        close
        @click:close="popupNotification.specificUserList.splice(index, 1)"
      >
        {{ user }}
      </v-chip>
    </div>

    <v-alert
      v-if="getUserError"
      type="error"
      outlined
      dense
      dismissible
      class="span-2"
    >
      No user found with this email
    </v-alert>

    <v-text-field
      v-if="popupNotification.type === 1"
      label="Search User by Email"
      append-icon="mdi-magnify"
      @click:append="getUser($event.target.value)"
      @keyup.enter="getUser($event.target.value)"
      @input="getUserError = false"
      outlined
      persistent-hint
      class="span-2"
      :loading="getUserLoading"
    />

    <v-text-field
      v-model="popupNotification.schedule.startDate"
      label="Start Date"
      type="date"
      outlined
      persistent-hint
      :rules="[required()]"
      :min="new Date().toISOString().substr(0, 10)"
      class="span-1"
    />

    <v-text-field
      v-model="popupNotification.schedule.endDate"
      label="End Date"
      type="date"
      outlined
      persistent-hint
      :rules="[
        required(),
        (v) =>
          v > popupNotification.schedule.startDate ||
          'End date must be greater than start date'
      ]"
      :min="
        popupNotification.schedule.startDate ||
        new Date().toISOString().substr(0, 10)
      "
      :disabled="!popupNotification.schedule.startDate"
      class="span-1"
    />

    <div class="span-2 d-flex align-center justify-space-between mb-2">
      <h2>Buttons</h2>
      <v-btn
        v-if="popupNotification.buttons.length < 4"
        elevation="0"
        color="green"
        dark
        small
        class="d-flex ml-auto"
        @click="
          popupNotification.buttons.push({
            label: '',
            link: ''
          })
        "
      >
        <v-icon small class="mr-1 ml-n2">mdi-plus</v-icon>
        Add Button
      </v-btn>
    </div>

    <v-card
      v-for="(button, index) in popupNotification.buttons"
      :key="index"
      outlined
      class="span-2 mb-4"
    >
      <v-card-title>
        <p class="span-2">Button {{ index + 1 }}</p>
        <v-btn
          v-if="popupNotification.buttons.length > 1"
          icon
          @click="handleRemoveButton(index)"
          class="ml-auto"
        >
          <v-icon v-if="isremovingButton">mdi-loading mdi-spin </v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="button.label"
          label="Button Text"
          dense
          outlined
          persistent-hint
          class="span-2"
          :rules="[
            required(),
            (v) => v.length <= 30 || 'Max length is 30 characters'
          ]"
        />
        <v-text-field
          v-model="button.link"
          label="Redirect Link"
          dense
          outlined
          persistent-hint
          class="span-2"
          :rules="[urlValidator()]"
        />
      </v-card-text>
    </v-card>

    <loading-dialog
      v-model="loading"
      message="Fetching Popup Notification Data"
    />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import { PopupNotificationsService } from '@/services/popup-notifications-service';
import { required, urlValidator } from '@/utils/validators';
import { storage, users } from '@/plugins/firebase';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new PopupNotificationsService(),
    getUserLoading: false,
    getUserError: false,
    isremovingButton: false,
    totalButtons: 0,

    oldContent: [],
    popupNotificationTypes: [
      { text: 'All Users', value: 0 },
      { text: 'Specific Users', value: 1 },
      { text: 'Premium Users', value: 2 },
      { text: 'Non-Premium Users', value: 3 }
    ],

    indexes: [
      { text: '0', value: 0 },
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
      { text: '6', value: 6 },
      { text: '7', value: 7 },
      { text: '8', value: 8 },
      { text: '9', value: 9 },
      { text: '10', value: 10 }
    ],
    popupNotifications: [],
    oldPopupNotification: {},
    popupNotification: {
      content: [
        {
          title: '',
          description: '',
          image: ''
        }
      ],
      schedule: {
        startDate: null,
        endDate: null
      },
      oneTime: true,
      oncePerDay: false,
      notificationTime: true,
      type: 0,
      specificUserList: [],
      button: '',
      link: '',
      buttons: [
        {
          label: '',
          link: ''
        }
      ],
      active: true,
      ios: true,
      android: true,
      web: true
    }
  }),

  async mounted() {
    await this.loadAllPopupNotifications();

    this.loadPopupNotification();
  },

  methods: {
    required,
    urlValidator,
    formatDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },

    handleNotificationTime() {
      const notificationTime = this.popupNotification.notificationTime;
      if (notificationTime == true) {
        this.popupNotification.oneTime = true;
        this.popupNotification.oncePerDay = false;
      } else if (notificationTime == false) {
        this.popupNotification.oneTime = false;
        this.popupNotification.oncePerDay = false;
      } else if (notificationTime == 'oncePerDay') {
        this.popupNotification.oneTime = true;
        this.popupNotification.oncePerDay = true;
      }
    },

    async loadAllPopupNotifications() {
      this.loading = true;
      try {
        this.popupNotifications = await this.service.fetchAll();

        this.indexes = this.popupNotifications.map((popup, index) => ({
          text: index + 1,
          value: index + 1
        }));
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async getUser(email) {
      if (
        !email ||
        this.popupNotification?.specificUserList?.includes(email.trim())
      )
        return;
      this.getUserError = false;
      this.getUserLoading = true;
      try {
        const user = await users
          .where('email', '==', email.trim())
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              this.getUserError = true;
              return;
            }
            return querySnapshot.docs[0].data();
          });
        this.popupNotification.specificUserList.push(user.email);
      } catch (e) {
        this.userError = true;
      }

      this.getUserLoading = false;
    },
    previewImage(content) {
      if (content.imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          content.image = e.target.result;
        };
        reader.readAsDataURL(content.imageFile);
      }
    },
    async loadPopupNotification() {
      if (!this.$route.query.id) {
        this.indexes.push({
          text: this.popupNotifications.length + 1,
          value: this.popupNotifications.length + 1
        });
        this.popupNotification.index = this.popupNotifications.length + 1;
        return;
      }
      this.isEdit = true;
      this.loading = true;
      this.popupNotification = await this.service.fetchOne(
        this.$route.query.id
      );
      this.oldPopupNotification = JSON.parse(
        JSON.stringify(this.popupNotification)
      );
      this.oldContent = JSON.parse(
        JSON.stringify(this.popupNotification.content)
      );
      this.popupNotification.schedule.startDate = this.formatDate(
        this.popupNotification.schedule.startDate
      );
      this.popupNotification.schedule.endDate = this.formatDate(
        this.popupNotification.schedule.endDate
      );
      if (this.popupNotification.specificUserList === null) {
        this.popupNotification.specificUserList = [];
      }

      if (
        this.popupNotification.oneTime == true &&
        this.popupNotification.oncePerDay == false
      ) {
        this.popupNotification.notificationTime = true;
      } else if (
        this.popupNotification.oneTime == false &&
        this.popupNotification.oncePerDay == false
      ) {
        this.popupNotification.notificationTime = false;
      } else if (
        this.popupNotification.oneTime == true &&
        this.popupNotification.oncePerDay == true
      ) {
        this.popupNotification.notificationTime = 'oncePerDay';
      }

      this.totalButtons = this.popupNotification.buttons.length;

      this.loading = false;
    },
    preCheck(context) {
      return context.validate();
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          context.changeLoadingMessage('Updating Popup Notification');
          try {
            for (let i = 0; i < this.popupNotification.content.length; i++) {
              if (this.popupNotification.content[i].imageFile) {
                const imageRef = storage.ref(
                  `popup-notifications/${Date.now()}-${
                    this.popupNotification.content[i].imageFile.name
                  }`
                );
                await imageRef.put(this.popupNotification.content[i].imageFile);

                // delete old image
                const oldImage = this.oldContent[i]?.image;
                if (oldImage) {
                  await storage.refFromURL(oldImage).delete();
                }

                this.popupNotification.content[i].image =
                  await imageRef.getDownloadURL();
              }
            }

            this.popupNotification.schedule.startDate = new Date(
              this.popupNotification.schedule.startDate
            );
            this.popupNotification.schedule.endDate = new Date(
              this.popupNotification.schedule.endDate
            );

            await this.service.update(this.popupNotification);

            if (
              this.oldPopupNotification.index !== this.popupNotification.index
            ) {
              const index = this.popupNotification.index;
              const popupNotification = this.popupNotifications.find(
                (popup) => popup.index === index
              );
              await this.service.update({
                ...popupNotification,
                index: this.oldPopupNotification.index
              });
            }

            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating Popup Notification',
              description: e.toString()
            });
            return false;
          }
        } else {
          context.changeLoadingMessage('Creating A New Popup Notification');

          try {
            for (let i = 0; i < this.popupNotification.content.length; i++) {
              if (this.popupNotification.content[i].imageFile) {
                const storageRef = storage.ref(
                  `popup-notifications/${Date.now()}-${
                    this.popupNotification.content[i].imageFile.name
                  }`
                );
                await storageRef.put(
                  this.popupNotification.content[i].imageFile
                );
                this.popupNotification.content[i].image =
                  await storageRef.getDownloadURL();
              }
            }

            this.popupNotification.schedule.startDate = new Date(
              this.popupNotification.schedule.startDate
            );
            this.popupNotification.schedule.endDate = new Date(
              this.popupNotification.schedule.endDate
            );
            this.popupNotification.button =
              this.popupNotification.buttons[0].label;
            this.popupNotification.link =
              this.popupNotification.buttons[0].link;

            await this.service.create(this.popupNotification);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating Popup Notification',
              description: e.toString()
            });
            return false;
          }
        }
      }
    },

    async handleRemoveButton(index) {
      if (!this.isEdit) {
        this.popupNotification.buttons.splice(index, 1);
        return;
      }

      if (
        index >= this.totalButtons ||
        confirm(
          'Are you sure you want to delete this button? \nThis action cannot be undone!'
        )
      ) {
        try {
          if (index < this.totalButtons) {
            this.isRemovingButton = true;
            await this.service.deletePopupNotificationButton({
              id: this.popupNotification.id,
              index
            });

            this.$toast.success('Button deleted successfully');
          }

          this.popupNotification.buttons.splice(index, 1);
        } catch (e) {
          console.error(e);
        } finally {
          this.isRemovingButton = false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
