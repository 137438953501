<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab>Active Categories</v-tab>
        <v-tab>InActive Categories</v-tab>
      </v-tabs>

      <data-table
        :loader="loadData"
        :headers="headers"
        title="Questions"
        :showSearch="false"
        :defaultFooter="false"
        :allow-add="
          getUser() ? getUser().scopes.includes('questions:new') : false
        "
        allow-filters
        @add-new="addNew"
        @filter="openFilter"
        @done="$router.back()"
        :delete-handler="
          getUser()
            ? getUser().scopes.includes('questions:delete')
              ? service.delete
              : null
            : null
        "
        :edit-handler="
          getUser()
            ? getUser().scopes.includes('questions:edit')
              ? edit
              : null
            : null
        "
      >
        <template #primary-action>
          <div style="width: 400px; margin-right: 20px; max-width: 100%">
            <v-text-field
              v-model="search"
              outlined
              label="Search by statement..."
              dense
              hide-details
              append-icon="mdi-magnify"
              @click:append="searchData"
              @change="searchData"
              clearable
            />
          </div>

          <v-btn
            v-if="getUser() && getUser().scopes.includes('questions:import')"
            @click="openUploadQuestions"
            elevation="0"
            color="primary"
            outlined
            >Import
          </v-btn>

          <v-menu
            offset-y
            v-if="getUser() && getUser().scopes.includes('questions:export')"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary mx-3"
                v-bind="attrs"
                v-on="on"
                elevation="0"
              >
                Export
                <v-icon right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="flex flex-col pa-2">
              <v-btn
                color="primary"
                class="my-1"
                :disabled="items.length <= 0"
                @click="exportAllQuestions(false)"
              >
                Export
              </v-btn>
              <v-btn
                color="primary"
                class="my-1"
                @click="exportAllQuestions(true)"
              >
                Export All
              </v-btn>
            </v-list>
          </v-menu>

          <v-btn
            v-if="getUser() && getUser().scopes.includes('questions:new')"
            @click="generateIdeas"
            elevation="0"
            class="primary mr-2"
          >
            <v-icon left> mdi-lightbulb-on </v-icon>
            Generate Ideas
          </v-btn>
        </template>
        <template #extra-actions="{ item }">
          <v-icon
            v-if="getUser() && getUser().scopes.includes('comments:view')"
            small
            color="green"
            @click="openComments(item)"
            >mdi-message
          </v-icon>
        </template>
        <template v-slot:category="{ item }">
          {{
            categories.find((x) => x.id === item.category)
              ? categories.find((x) => x.id === item.category).name
              : 'No Category'
          }}
        </template>
        <template v-slot:subCategory="{ item }">
          {{
            categories.find((x) => x.id === item.category) &&
            categories
              .find((x) => x.id === item.category)
              .subCategories.find((x) => x.id === item.subCategory)
              ? categories
                  .find((x) => x.id === item.category)
                  .subCategories.find((x) => x.id === item.subCategory).name
              : 'No Sub-Category'
          }}
        </template>
        <template v-slot:createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:type="{ item }">
          {{ getType(item.type) }}
        </template>
        <template #trial="{ item }">
          <v-checkbox
            hide-details
            dense
            v-model="item.trial"
            @change="toggleTrial($event, item)"
          />
        </template>
        <template #for_passage="{ item }">
          <v-checkbox hide-details dense v-model="item.for_passage" readonly />
        </template>

        <template v-slot:custom-footer>
          <div class="v-data-footer d-flex justify-center align-center">
            <v-pagination
              :value="currentPage + 1"
              class="my-2"
              @input="changePage"
              total-visible="10"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :length="totalPages"
            />
            <v-text-field
              v-model="pageInput"
              type="number"
              min="1"
              :max="totalPages"
              outlined
              dense
              hide-details
              style="max-width: 80px"
              placeholder="Page #"
              v-on:keydown.enter="changePage(parseInt(pageInput), true)"
            />

            <v-chip class="ml-4" color="primary"
              >Total Questions: {{ total_question_count }}</v-chip
            >
          </div>
        </template>
      </data-table>
    </v-card>

    <v-dialog width="40%" v-model="showFilter">
      <v-card>
        <div v-if="!loading">
          <div class="d-flex items-center">
            <v-card-title>Filter By Field</v-card-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="showFilter = false" class="mt-n2 mr-4">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <v-form class="pa-5" ref="filter">
            <v-select
              ref="filter-fields"
              v-model="filter.fields"
              outlined
              dense
              label="Fields"
              :items="fields"
              item-text="label"
              clearable
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  color="primary"
                  small
                  outlined
                  close
                  @click:close="filter.fields.splice(index, 1)"
                  class="mt-2 mb-1"
                >
                  {{ item.label }}
                </v-chip>
              </template>

              <template v-slot:append-item>
                <v-divider class="my-2"></v-divider>
                <div class="d-flex justify-end align-center mr-2">
                  <v-btn
                    elevation="0"
                    outlined
                    color="primary"
                    @click="$refs['filter-fields'].$refs.menu.isActive = false"
                    class="ml-auto"
                    >Done</v-btn
                  >
                </div>
              </template>
            </v-select>

            <div style="display: flex; gap: 20px">
              <v-select
                :rules="[required()]"
                :items="categories"
                return-object
                item-text="name"
                @change="getSubCategories"
                v-if="
                  filter && filter.fields && filter.fields.includes('category')
                "
                v-model="filter.category"
                outlined
                dense
                label="Category"
              ></v-select>
              <v-select
                :rules="[required()]"
                :items="subCategories"
                return-object
                item-text="name"
                v-if="
                  filter &&
                  filter.fields &&
                  filter.fields.includes('subcategory')
                "
                v-model="filter.subcategory"
                outlined
                dense
                label="Sub Category"
              ></v-select>
            </div>
            <v-select
              :rules="[required()]"
              v-if="
                filter && filter.fields && filter.fields.includes('explanation')
              "
              v-model="filter.explanation"
              outlined
              dense
              label="Has Explanation"
              :items="trials"
              item-text="label"
            ></v-select>
            <div style="display: flex; gap: 20px">
              <v-select
                :rules="[required()]"
                v-if="
                  filter && filter.fields && filter.fields.includes('trial')
                "
                v-model="filter.trial"
                outlined
                dense
                label="Trial"
                :items="trials"
                item-text="label"
              ></v-select>
              <v-select
                :rules="[required()]"
                v-if="filter && filter.fields && filter.fields.includes('type')"
                v-model="filter.type"
                outlined
                dense
                label="Type"
                :items="types"
                item-text="label"
              ></v-select>
            </div>
            <v-select
              :rules="[required()]"
              v-if="
                filter && filter.fields && filter.fields.includes('passage')
              "
              v-model="filter.passage"
              outlined
              dense
              label="Passage"
              :items="trials"
              item-text="label"
            ></v-select>
            <v-select
              :rules="[required()]"
              v-if="
                filter && filter.fields && filter.fields.includes('mediaCount')
              "
              v-model="filter.mediaCount"
              outlined
              dense
              label="Media Count"
              :items="fields"
              item-text="label"
            ></v-select>
            <v-text-field
              :rules="[required()]"
              v-if="filter && filter.fields && filter.fields.includes('date')"
              v-model="filter.created"
              outlined
              dense
              type="date"
              label="Created At"
            ></v-text-field>
            <v-select
              v-if="filter"
              :rules="[required()]"
              v-model="filter.sort"
              outlined
              dense
              label="Sort"
              :items="orders"
              item-text="label"
            ></v-select>
            <div class="d-flex justify-end">
              <v-btn
                @click="filterClear"
                class="mr-2"
                elevation="0"
                outlined
                color="primary"
                >Clear</v-btn
              >
              <v-btn
                @click="applyFilter"
                class="ml-2"
                color="primary"
                elevation="0"
                >Apply</v-btn
              >
            </div>
          </v-form>
        </div>
        <div v-else class="pa-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate />
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent width="50%" v-model="showFileUpload">
      <v-card class="px-5 py-7">
        <div v-if="!isUploading">
          <v-card-title class="px-0">{{
            isUploaded ? 'Questions Imported' : 'Import Questions By CSV'
          }}</v-card-title>
          <v-form ref="import">
            <div v-if="isUploaded">
              <div class="d-flex py-5">
                <v-chip class="mr-2" color="primary"
                  >Total: {{ this.questions.length }}</v-chip
                >
                <v-chip class="mr-2" color="success"
                  >Completed: {{ this.added }}
                </v-chip>
                <v-chip class="mr-2" color="error"
                  >Rejected: {{ this.questions.length - this.added }}</v-chip
                >
              </div>
              <div class="mb-2">
                <ul
                  style="color: red"
                  v-for="(error, i) of importErrors"
                  :key="i"
                >
                  <li>{{ error.text }} at entry # {{ error.item }}</li>
                </ul>
              </div>
              <div class="d-flex justify-end">
                <v-btn @click="cancelUpload" class="mr-2">OK</v-btn>
                <v-btn @click="newImport" class="ml-2" color="primary"
                  >New Import</v-btn
                >
              </div>
            </div>
            <div v-else>
              <p>
                Download a
                <a
                  href="/question_template.csv"
                  download="question_template.csv"
                  >sample CSV template</a
                >
                to see an example of the format required.
              </p>
              <p>
                Download
                <a href="javascript:void(0)" @click="download"
                  >Categories & Sub-Categories</a
                >
                reference sheet to refer to their ids.
              </p>
              <v-file-input
                :rules="[required()]"
                v-model="uploadFile"
                outlined
                dense
                label="Upload CSV"
                prepend-icon=""
                accept=".csv"
                @change="bulkUpload"
              />
              <div class="d-flex justify-end">
                <div>
                  <v-btn @click="cancelUpload" class="mr-2">Cancel</v-btn>
                  <v-btn @click="uploadQuestions" class="ml-2" color="primary"
                    >Upload</v-btn
                  >
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div v-else class="pa-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate class="mr-3" />
          <p class="ma-0">Importing Questions...</p>
        </div>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loading" message="Loading..." />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import { QuestionsService } from '@/services/questions-service';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import { CategoryService } from '@/services/category-service';
import LoadingDialog from '@/components/LoadingDialog';
import moment from 'moment';
import Papa from 'papaparse';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    tab: 0,
    pageInput: '',
    loading: false,
    service: new QuestionsService(),
    categoryService: new CategoryService(),
    search: '',
    backByInactive: false,

    total_question_count: 0,
    totalPages: 0,
    currentPage: 0,
    recordsPerPage: 10,

    isActiveQuestions: true,

    headers: [
      {
        text: 'Question Statement',
        value: 'statement',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category',
        sortable: false
      },
      {
        text: 'Sub Category',
        value: 'subCategory',
        sortable: false
      },
      {
        text: 'Type',
        value: 'type',
        sortable: false
      },
      {
        text: 'Media Count',
        value: 'mediaCount',
        sortable: false
      },
      {
        text: 'Trial',
        value: 'trial',
        sortable: false
      },
      {
        text: 'Passage',
        value: 'for_passage',
        sortable: false
      }
    ],

    isFilter: false,

    showFilter: false,

    items: [],
    filter: {
      fields: [],
      sort: 'true'
    },
    fields: [
      {
        label: 'Category',
        value: 'category'
      },
      {
        label: 'Sub-Category',
        value: 'subcategory'
      },
      {
        label: 'Trial',
        value: 'trial'
      },
      {
        label: 'Type',
        value: 'type'
      },
      {
        label: 'Passage',
        value: 'passage'
      },
      {
        label: 'Explanation',
        value: 'explanation'
      },
      {
        label: 'Media Count',
        value: 'mediaCount'
      },
      {
        label: 'Date',
        value: 'date'
      }
    ],
    types: [
      {
        label: 'True / False',
        value: 'bool'
      },
      {
        label: 'Multiple Choice',
        value: 'choices'
      }
    ],
    trials: [
      {
        label: 'Yes',
        value: 'true'
      },
      {
        label: 'No',
        value: 'false'
      }
    ],
    orders: [
      {
        label: 'Ascending',
        value: 'asc'
      },
      {
        label: 'Descending',
        value: 'desc'
      }
    ],

    categories: [],
    subCategories: [],

    showFileUpload: false,
    allSubs: [],
    uploadFile: null,
    isUploaded: false,
    isUploading: false,
    questions: [],
    importErrors: [],
    added: 0
  }),

  watch: {
    async tab() {
      if (this.backByInactive === false) {
        this.pageInput = '';
        this.currentPage = 0;

        this.$router.replace({ path: '/questions', hash: '', query: null });
        this.refreshDataTable();
      }
    }
  },

  methods: {
    getUser,
    required,

    generateIdeas() {
      this.$router.push('/questions/ideas');
    },

    refreshDataTable() {
      document.getElementById('searchDataReload').click();
    },

    changePage(value, byPageInput = false) {
      // Update route hash for pagination
      if (value > 0 && value <= this.totalPages) {
        this.$router.replace({
          path: '/questions',
          query: {
            tab: this.tab,
            category: this.filter.category ? this.filter.category.id : null,
            subcategory: this.filter.subcategory
              ? this.filter.subcategory.id
              : null,
            trial: this.filter.trial ? this.filter.trial : null,
            type: this.filter.type ? this.filter.type : null,
            passage: this.filter.passage ? this.filter.passage : null,
            explanation: this.filter.explanation
              ? this.filter.explanation
              : null,
            sort: this.filter.sort,
            created: this.filter.created ? this.filter.created : null,
            fields: this.filter.fields,
            mediaCount: this.filter.field === 'mediaCount' ? true : false
          },
          hash: '#' + value
        });
      }
      if (byPageInput === false) {
        this.pageInput = '';
      }

      this.refreshDataTable();
    },

    async searchData() {
      this.pageInput = '';
      this.$router.replace({
        path: '/questions',
        hash: '#1'
      });
      // await this.loadData(true);
      this.refreshDataTable();
    },

    async toggleTrial(value, item) {
      if (confirm('Warning! \n Are you sure?')) {
        this.loading = true;
        try {
          const data = {
            ...item,
            trial: value
          };
          await this.service.update(data);
        } catch (e) {
          window.console.log(e);
        }
        this.loading = false;
      }
    },

    getType(type) {
      if (type === 'bool') {
        return 'True False';
      } else {
        return 'Multiple Choice';
      }
    },

    addNew() {
      this.$router.push('/question');
    },

    edit(item) {
      this.$router.push({
        path: '/question',
        query: {
          id: item.id,
          isActiveCategory: this.tab === 0,
          tab: this.tab,
          category: this.filter.category ? this.filter.category.id : null,
          subcategory: this.filter.subcategory
            ? this.filter.subcategory.id
            : null,
          trial: this.filter.trial ? this.filter.trial : null,
          type: this.filter.type ? this.filter.type : null,
          passage: this.filter.passage ? this.filter.passage : null,
          explanation: this.filter.explanation ? this.filter.explanation : null,
          sort: this.filter.sort,
          created: this.filter.created ? this.filter.created : null,
          // field: this.filter.field,
          mediaCount: this.filter.field === 'mediaCount' ? true : false
        }
      });
    },

    async loadData(search = false) {
      this.loading = true;
      if (this.$route.query.tab) {
        this.backByInactive = true;
        this.tab = parseInt(this.$route.query.tab);
      }
      this.tab === 0
        ? (this.isActiveQuestions = true)
        : (this.isActiveQuestions = false);

      // Fetch route hash for pagination
      this.fetchRouteHash();

      await this.getCategories(this.tab == 0 ? false : true);

      let routeQueries = this.$route.query;

      if (routeQueries.subcategory) {
        this.subCategories = this.categories.find(
          (x) => x.id === routeQueries.category
        ).subCategories;
      }

      this.filter = {
        fields: routeQueries.fields ? routeQueries.fields : [],
        tab: routeQueries.tab ? routeQueries.tab : 0,
        category: routeQueries.category
          ? this.categories.find((x) => x.id === routeQueries.category)
          : null,
        subcategory: routeQueries.subcategory
          ? this.subCategories.find((x) => x.id === routeQueries.subcategory)
          : null,
        trial: routeQueries.trial ? routeQueries.trial : null,
        type: routeQueries.type ? routeQueries.type : null,
        passage: routeQueries.passage ? routeQueries.passage : null,
        explanation: routeQueries.explanation ? routeQueries.explanation : null,
        sort: routeQueries.sort ? routeQueries.sort : 'true',
        created: routeQueries.created ? routeQueries.created : null,
        mediaCount: routeQueries.mediaCount === 'true' ? true : false
      };

      if (typeof this.filter.fields === 'string') {
        this.filter.fields = [this.filter.fields];
      }

      if (this.filter.fields.length > 0) {
        this.isFilter = true;
      }

      if (this.isFilter) {
        await this.filterData();
        this.backByInactive = false;
      } else {
        let res;
        if (search) {
          res = await this.service.paginate(
            this.isActiveQuestions,
            this.currentPage,
            this.recordsPerPage,
            this.search
          );
        } else {
          res = await this.service.paginate(
            this.isActiveQuestions,
            this.currentPage,
            this.recordsPerPage
          );
        }
        this.items = res.data;
        this.totalPages = res.total_pages;
        this.total_question_count = res.total_question_count;
      }

      this.loading = false;

      // this.items = this.items.map((item) => {
      //   let category = this.categories.find((x) => x.id === item.category) || {
      //     name: 'No Category',
      //     subCategories: []
      //   };
      //   let subCategory = category.subCategories.find(
      //     (x) => x.id === item.subCategory
      //   ) || { name: 'No Sub-Category' };
      //   item.category = category.name;
      //   item.subCategory = subCategory ? subCategory.name : 'No Sub-Category';
      //   return item;
      // });

      return this.items;
    },

    fetchRouteHash() {
      if (this.$route.hash === '') {
        this.currentPage = 0;
      } else {
        let hash = parseInt(this.$route.hash.replace('#', ''));
        this.currentPage = hash - 1;
        if (
          hash <= 0 ||
          (this.currentPage > this.totalPages && this.totalPages > 0)
        ) {
          this.$router.replace({ path: '/questions', hash: '#1' });
        }
      }
    },

    openFilter() {
      this.showFilter = true;
    },

    applyFilter() {
      if (this.$refs.filter.validate()) {
        this.isFilter = true;
        this.pageInput = '';
        this.$router.replace({
          path: '/questions',
          hash: '#1',

          query: {
            tab: this.tab,
            fields: this.filter.fields,
            category:
              this.filter.fields.includes('category') ||
              this.filter.fields.includes('subcategory')
                ? this.filter.category.id
                : null,
            subcategory: this.filter.fields.includes('subcategory')
              ? this.filter.subcategory.id
              : null,
            trial: this.filter.fields.includes('trial')
              ? this.filter.trial
              : null,
            type: this.filter.fields.includes('type') ? this.filter.type : null,
            passage: this.filter.fields.includes('passage')
              ? this.filter.passage
              : null,
            explanation: this.filter.fields.includes('explanation')
              ? this.filter.explanation
              : null,
            sort: this.filter.sort,
            created: this.filter.fields.includes('date')
              ? this.filter.created
              : null,
            mediaCount: this.filter.fields.includes('mediaCount') ? true : false
          }
        });

        this.refreshDataTable();
      }
    },

    async filterData() {
      let query;
      query = 'sort_order=' + this.filter.sort;

      this.filter.fields.forEach((field) => {
        if (field === 'category') {
          query += '&type=0&category=' + this.filter.category.id;
        } else if (field === 'subcategory') {
          query += '&type=1&subcategory=' + this.filter.subcategory.id;
        } else if (field === 'trial') {
          query += '&type=2&trial=' + (this.filter.trial === 'true');
        } else if (field === 'type') {
          query += '&type=3&question-type=' + this.filter.type;
        } else if (field === 'date') {
          const date = new Date(this.filter.created);
          const startDate = moment(date).format('YYYY-MM-DD');
          const nextDay = new Date(date);
          nextDay.setDate(date.getDate() + 1);
          const endDate = moment(nextDay).format('YYYY-MM-DD');
          query += '&type=4&from=' + startDate + '&to=' + endDate;
        } else if (field === 'passage') {
          query += '&type=5&passage=' + (this.filter.passage === 'true');
        } else if (field === 'explanation') {
          query +=
            '&type=6&explanation=' + (this.filter.explanation === 'true');
        } else if (field === 'mediaCount') {
          query += '&sort_by=mediaCount';
        }
      });

      this.showFilter = false;
      let res = await this.service.paginate(
        this.isActiveQuestions,
        this.currentPage,
        this.recordsPerPage,
        this.search,
        query
      );
      this.items = res.data;
      this.totalPages = res.total_pages;
      this.total_question_count = res.total_question_count;
      this.isFilter = true;
    },

    filterClear() {
      this.$refs.filter.reset();
      this.filter = {
        fields: [],
        sort: 'true'
      };
      this.search = '';
      this.isFilter = false;
      this.showFilter = false;
      this.$router.replace({
        query: null
      });

      this.refreshDataTable();
    },

    cancelFilter() {
      this.showFilter = false;
    },

    async getCategories(isInactive = false) {
      this.categories = isInactive
        ? await this.categoryService.fetchAllInactive()
        : await this.categoryService.fetchAll();
    },

    getSubCategories(item) {
      this.subCategories = item.subCategories;
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY - H:M');
    },

    openUploadQuestions() {
      this.showFileUpload = true;
    },

    bulkUpload(item) {
      if (item) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const result = Papa.parse(data.toString(), {
            header: true,
            transformHeader: function (header) {
              return header.toLowerCase();
            },
            skipEmptyLines: true
          });
          this.questions = result.data;
        };
        reader.readAsText(item);
      }
    },

    async uploadQuestions() {
      if (this.$refs.import.validate()) {
        this.added = 0;
        this.isUploading = true;
        this.isUploaded = false;
        this.importErrors = [];
        for (let i = 0; i < this.questions.length; i++) {
          let item = this.questions[i];
          let error = false;
          const index = this.questions.indexOf(item);
          const question = {};
          if (item.statement) {
            question.statement = item.statement;
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Statement is missing'
            });
            error = true;
          }
          if (item.type && (item.type === 'choices' || item.type === 'bool')) {
            question.type = item.type;
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Type is missing or wrong'
            });
            error = true;
          }
          if (item.category) {
            const category = this.categories.find(
              (x) => x.id === item.category
            );
            if (category) {
              this.subCategories = category.subCategories;
              question.category = {
                id: category.id,
                name: category.name
              };
            } else {
              this.importErrors.push({
                item: index + 2,
                text: 'Question Category is wrong'
              });
              error = true;
            }
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Category is missing'
            });
            error = true;
          }
          if (item.subcategory) {
            const subCategory = this.subCategories.find(
              (x) => x.id === item.subcategory
            );
            if (subCategory) {
              question.subcategory = {
                id: subCategory.id,
                name: subCategory.name
              };
            } else {
              this.importErrors.push({
                item: index + 2,
                text: 'Question Sub-Category is wrong'
              });
              error = true;
            }
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Subcategory is missing'
            });
            error = true;
          }
          if (item.explanation) {
            question.explanation = item.explanation;
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Explanation is missing'
            });
            error = true;
          }
          if (item.type === 'choices') {
            if (item.a && item.b && item.c && item.d) {
              question.options = [item.a, item.b, item.c, item.d];
              if (
                item.answer &&
                (item.answer === 'a' ||
                  item.answer === 'b' ||
                  item.answer === 'c' ||
                  item.answer === 'd')
              ) {
                switch (item.answer) {
                  case 'a':
                    question.answer = 0;
                    break;
                  case 'b':
                    question.answer = 1;
                    break;
                  case 'c':
                    question.answer = 2;
                    break;
                  case 'd':
                    question.answer = 3;
                    break;
                  default:
                    this.importErrors.push({
                      item: index + 1,
                      text: 'Question Answer is missing or wrong'
                    });
                    error = true;
                }
              } else {
                this.importErrors.push({
                  item: index + 2,
                  text: 'Question Answer is missing or wrong'
                });
                error = true;
              }
            } else {
              this.importErrors.push({
                item: index + 2,
                text: 'Question Choices are missing'
              });
              error = true;
            }
          } else {
            if (item.a && item.b) {
              question.options = [item.a, item.b];
              if (item.answer && (item.answer === 'a' || item.answer === 'b')) {
                switch (item.answer) {
                  case 'a':
                    question.answer = 0;
                    break;
                  case 'b':
                    question.answer = 1;
                    break;
                  default:
                    this.importErrors.push({
                      item: index + 2,
                      text: 'Question Answer is missing or wrong'
                    });
                    error = true;
                }
              } else {
                this.importErrors.push({
                  item: index + 2,
                  text: 'Question Answer is missing or wrong'
                });
                error = true;
              }
            } else {
              this.importErrors.push({
                item: index + 2,
                text: 'Question Answer is missing or wrong'
              });
              error = true;
            }
          }
          question.images = [];
          question.pdfs = [];
          question.videos = [];
          question.trial = item.trial.toLowerCase() === 'true';
          question.for_passage = item.passage.toLowerCase() === 'true';
          question.createdAt = new Date();
          if (!error) {
            await this.service
              .create(question)
              .then(() => {
                this.added += 1;
                return true;
              })
              .catch((e) => {
                this.importErrors.push({
                  item: index + 2,
                  text: e
                });
              });
          }
        }
        this.isUploading = false;
        this.isUploaded = true;
      }
    },

    cancelUpload() {
      if (this.isUploaded) {
        document.getElementById('refresh').click();
      }
      this.uploadFile = null;
      this.isUploaded = false;
      this.isUploading = false;
      this.showFileUpload = false;
    },

    newImport() {
      this.questions = [];
      this.uploadFile = null;
      this.importErrors = [];
      this.isUploading = false;
      this.isUploaded = false;
    },

    async download() {
      let name, blob;
      const csv = this.toCSV(this.categories);
      name = 'categories-subCategories.csv';
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str =
        'Category Name,Category ID,Sub-Category Name,Sub-Category ID\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        array[i].name = array[i].name.replaceAll(',', ' ');
        line += array[i].name + ',' + array[i].id;
        line += '\r\n';

        for (let j = 0; j < array[i].subCategories.length; j++) {
          array[i].subCategories[j].name = array[i].subCategories[
            j
          ].name.replaceAll(',', ' ');
          line +=
            ',,' +
            array[i].subCategories[j].name +
            ',' +
            array[i].subCategories[j].id;
          line += '\r\n';
        }

        str += line + '\r\n';
      }

      return str;
    },

    async exportAllQuestions(allQuestions) {
      if (allQuestions) {
        let items = await this.service.fetchAll();
        this.exportQuestions(items);
      } else {
        const items = [...this.items];
        this.exportQuestions(items);
      }
    },

    exportQuestions(items) {
      this.loading = true;
      const csv = this.toCSVQuestions(items);
      const name = 'questions.csv';
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false;
    },

    toCSVQuestions(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : [...items];
      let str =
        'Statement,type,a,b,c,d,Answer,category,subcategory,explanation,trial,passage,% a,% b,% c,% d\r\n';
      for (let i = 0; i < array.length; i++) {
        const item = { ...array[i] };
        let line = '';
        item.statement = '"' + item.statement.replaceAll('"', "'") + '"';
        item.explanation = '"' + item.explanation.replaceAll('"', "'") + '"';
        line += item.statement + ',' + item.type + ',';
        if (item.options) {
          if (item.options[0]) {
            line +=
              ('"' + item.options[0]?.replaceAll('"', "'") + '"' ?? '') + ',';
          } else {
            line += ',';
          }
          if (item.options[1]) {
            line +=
              ('"' + item.options[1]?.replaceAll('"', "'") + '"' ?? '') + ',';
          } else {
            line += ',';
          }
          if (item.options[2]) {
            line +=
              ('"' + item.options[2]?.replaceAll('"', "'") + '"' ?? '') + ',';
          } else {
            line += ',';
          }
          if (item.options[3]) {
            line +=
              ('"' + item.options[3]?.replaceAll('"', "'") + '"' ?? '') + ',';
          } else {
            line += ',';
          }
        } else {
          line += 'TRUE,FALSE,,,';
        }

        line +=
          this.getAnswer(item.answer) +
          ',' +
          '"' +
          item.category.name +
          '"' +
          ',' +
          '"' +
          item.subCategory.name +
          '"' +
          ',' +
          item.explanation +
          ',' +
          item.trial +
          ',' +
          item.for_passage +
          ',' +
          item.percentage.join(',');

        str += line + '\r\n';
      }

      return str;
    },

    getAnswer(i) {
      switch (i) {
        case 0:
          return 'a';
        case 1:
          return 'b';
        case 2:
          return 'c';
        case 3:
          return 'd';
        default:
          return 'a';
      }
    },

    openComments(item) {
      this.$router.push(`/question/comment?id=${item.id}`);
    }
  }
};
</script>

<style scoped></style>
